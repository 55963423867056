import { saveAs } from 'file-saver';
import html2canvas from "html2canvas";
import { MyDoc } from '../PdfCreator/PdfCreator';
import { pdf } from '@react-pdf/renderer';
import { useState } from 'react';
import { Button, EButtonType } from '../Button';
import FormattedMessageCustom from '../FormattedMessageCustom';
import { defineMessages } from 'react-intl';

const translatableStrings = defineMessages({
  downloadPdf: {
    id: 'visualisation.downloadPdf',
    defaultMessage: 'Download PDF'
  },
});

type Props = {
  isExpertMode: boolean;
  intl: any;
  pdfData: unknown;
};

function DownloadButton({
  isExpertMode,
  intl,
  pdfData,
}: Props) {
  const [pdfGenerating, setPdfGenerating] = useState(false);

  const onDownloadPDF = () => {
    setPdfGenerating(true);
    let el = document.querySelector(".pdfChartWrapper");

    html2canvas((el as any), {
      'scale': 2,
      'width': 800,
      'height': 520,
      'onclone': (doc) => {
        const el = (doc as any).querySelector('.pdfChartWrapper');
        el.style.width = window.innerWidth;
        el.style.height = window.innerWidth / 1.45;
        el.style.visibility = "visible";
      },
      scrollY: 0,
      scrollX: 0
    }).then(canvas => {
      localStorage.setItem('chartImage', JSON.stringify(canvas.toDataURL()));

      (async () => {
        const doc = <MyDoc isExpert={isExpertMode}
          chartImage={canvas.toDataURL()}
          intl={intl}
          userData={pdfData} />;
        const asPdf = pdf([] as any); // {} is important, throws without an argument
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, 'document.pdf');
        setPdfGenerating(false);
      })();
    });
  };
  
  return <>
    <Button
      className="mt-0"
      isLoading={pdfGenerating}
      buttonType={EButtonType.InvertedPill}
      onClick={onDownloadPDF}>
      <FormattedMessageCustom id={translatableStrings.downloadPdf.id}
        text={intl.formatMessage(translatableStrings.downloadPdf)}>
        <span className=""></span>
      </FormattedMessageCustom>
    </Button>
  </>
}

export default DownloadButton;