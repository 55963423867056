import papaparse from 'papaparse'

function generateCSV(variables: any, results: any) {
  console.log(results)
  const variablesCSV = [
    ["Key", "Value"],
    ...Object.keys(variables).map((key) => {
      let label = key
      if (key === 'ccrit_c_mu') label = 'ccrit_a_mu';
      if (key === 'ccrit_c_sigma') label = 'ccrit_a_sigma';
      if (key === 'ccrit_t_mu') label = 'ccrit_b_mu';
      if (key === 'ccrit_t_sigma') label = 'ccrit_b_sigma';

      return [label, variables[key]];
    }),
    ["Year", "Steel A probability", "Steel B probability"],
    ...results[0].map((d, i) => ([ d.xYear, results[1][i].yProbability, d.yProbability ]))
  ];
  const csv = papaparse.unparse(variablesCSV)
  download('swiss-steel-results.csv', csv)
}

function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export { generateCSV }