import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../reducers';
import { toastr } from 'react-redux-toastr';
import * as actions from '../../actions';
import './Login.scss';
import FormattedMessageCustom from '../../components/FormattedMessageCustom';
import { defineMessages } from 'react-intl';
import { animateOutAndNavigate } from '../../utils/react';
import classNames from 'classnames';
import { Button, EButtonType } from '../../components/Button';
import LanguageSwitcher from "../../components/LanguageSwitcher";
import apiCaller from '../../utils/apiCaller';

const translatableStrings = defineMessages({
  title: {
    id: 'standort.title',
    defaultMessage: 'Projektstandort'
  },
  login: {
    id: 'navigation.login',
    defaultMessage: 'Login'
  },
  password: {
    id: 'navigation.password',
    defaultMessage: 'Password'
  }
})

interface State {
  password: any
}

interface Props {
  actions: any;
  history: any;
  userDataST: any;
  settings: any;
  intl: any;
}

class Login extends React.Component<Props, State> {
  password: null;

  constructor(props: any) {
    super(props);
    this.firstInput = React.createRef();
    this.state = {
      password: ''
    }
  }

  private firstInput = null;

  componentDidMount() {
    document.body.classList.add('standortPage');
  }

  componentWillUnmount() {
    document.body.classList.remove('standortPage');
  }

  submitLogin = (e) => {
    let self = this;
    animateOutAndNavigate(() => {
      self.props.history.push({
        pathname: `/expert`
      });
    })
    apiCaller('POST', 'auth/validatepassword/', { password: this.state.password })
      .then(() => {
        localStorage.setItem("isAuthenticated", "yes");
        animateOutAndNavigate(() => {
          self.props.history.push({
            pathname: `/expert`
          });
        })
      })
      .catch((err) => {
        toastr.error('The password you entered is incorrect.', '', { timeOut: 3000 });
      })
  }


  render() {
    return (
      <div className="standortContainer pageContainer">

        <div className="main">
          <div className="mainBg">
            <div className="header36 text-center stagger">Expert Mode</div>

            <form className="col-md-4 flexForm" onSubmit={(e) => {
              const tagName = (e.target as any).tagName;
              e.preventDefault();
              this.submitLogin(e);
            }}>
              <div
                className={classNames("cards stagger mb-5", {})}>
              </div>

              <FormattedMessageCustom id={translatableStrings.password.id}
                text={this.props.intl.formatMessage(translatableStrings.password)}>
                <div className="font-16 text-left font-500 inputTitle"></div>
              </FormattedMessageCustom>

              <input
                type="password"
                required
                className="customInput"
                onChange={(e) => {
                  this.setState({
                    password: e.target.value
                  })
                }}
                value={this.state.password}
              />


              <Button
                className="mx-auto mt-4 stagger"
                submit
                buttonType={EButtonType.PillMedium}
              >
                <FormattedMessageCustom id={translatableStrings.login.id}
                  text={this.props.intl.formatMessage(translatableStrings.login)}>
                  <span className=""></span>
                </FormattedMessageCustom>
              </Button>
            </form>
          </div>
        </div>
        <LanguageSwitcher isMobileMenu={true} intl={this.props.intl} />
      </div>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    ...state.app,
  }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    setUserDataStandort: (data) => dispatch(actions.setUserDataStandort(data)),
    setCalculateParameter: (data) => dispatch(actions.setCalculateParameter(data)),
    setSettingLabels: (data) => dispatch(actions.setSettingLabels(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
