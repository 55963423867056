import * as constants from '../constants';
import { toastr } from 'react-redux-toastr';
import apiCaller from '../utils/apiCaller';
import { mergeTranslations } from "../constants";
import { updateIntl } from 'react-intl-redux';

export interface ACTION {
  type: string;
  payload?: any;
}

export const setLoading = (payload: boolean): ACTION => ({
  type: constants.LOADING,
  payload
});

export function clearData(payload, dispatch) {
  dispatch(clearDataAction(payload));
  // this triggers settings refetch
  (window as any).location.reload(true);
}

export const clearDataAction = (payload: string): ACTION => ({
  type: constants.CLEAR_DATA,
  payload
});


export const setUserDataStandort = (payload: string): ACTION => ({
  type: constants.USERDATA_STANDORT,
  payload
});

export const setUserDataBauwerk = (payload: string): ACTION => ({
  type: constants.USERDATA_BAUWERK,
  payload
});

export const setUserDataBauwerkData = (payload: string): ACTION => ({
  type: constants.USERDATA_BAUWERK_DATA,
  payload
});

export const setUserDataBauteil = (payload: string): ACTION => ({
  type: constants.USERDATA_BAUTEIL,
  payload
});

export const setCalculateParameter = (payload: string): ACTION => ({
  type: constants.CALCULATE_PARAMETER,
  payload
});

export const setSettingLabels = (payload: string): ACTION => ({
  type: constants.SETTING_LABELS,
  payload
});

export const setUserDataConcreteType = (payload: string): ACTION => ({
  type: constants.USERDATA_CONCRETE_TYPE,
  payload
});

export const setUserDataThickness = (payload: string): ACTION => ({
  type: constants.USERDATA_THICKNESS,
  payload
});

export const changeChart = (payload: string): ACTION => ({
  type: constants.CHANGE_USERDATA,
  payload
});

export const setExpositionOptions = (payload: string): ACTION => ({
  type: constants.EXPOSITION_OPTIONS,
  payload
});

export const createNewChart = (payload: string): ACTION => ({
  type: constants.CREATE_NEW_CHART,
  payload
});

export const changeLocale = (payload: string): ACTION => ({
  type: constants.UPDATE_LOCALES,
  payload
});

export const initChartArchive = (payload: string): ACTION => ({
  type: constants.INIT_CHART_ARCHIVE,
  payload
})

export const fetchSettings = (payload) => wrap((dispatch) => {
  try {
    dispatch(setLoading(true));

    return apiCaller('GET', `options/all/`).then((resData) => {
      dispatch(setLoading(false));
      dispatch({ "type": constants.FETCHED_SETTINGS, "payload": [...resData] });
      return resData;
    })
  } catch (err) {
    dispatch(setLoading(false));
    throw (err);
  }
}, constants.FETCH_ERROR);

export const fetchVariables = (payload) => wrap((dispatch) => {
  try {
    dispatch(setLoading(true));

    return apiCaller('GET', `options/variables/`).then((resData) => {
      dispatch(setLoading(false));
      dispatch({ "type": constants.FETCHED_VARIABLES, "payload": resData });
      return resData;
    })
  } catch (err) {
    dispatch(setLoading(false));
    throw (err);
  }
}, constants.FETCH_ERROR);


export const fetchTranslations = (payload) => wrap((dispatch, getState) => {
  try {
    dispatch(setLoading(true));
    const { locale, messages } = getState().intl;

    return apiCaller('GET', `options/copy/`, null, { "Accept-Language": locale }).then((resData) => {
      const newMessages = mergeTranslations(messages, resData)
      dispatch(setLoading(false));
      dispatch(updateIntl({ locale, messages: newMessages }));
      return resData;
    })
  } catch (err) {
    dispatch(setLoading(false));
    throw (err);
  }
}, constants.FETCH_ERROR);


export const fetchResults = (payload) => wrap((dispatch) => {
  try {
    dispatch(setLoading(true));
    
    return apiCaller('POST', `options/calculate/`, payload).then((resData) => {
      dispatch(setLoading(false));
      resData['input_parameters'] = payload;
      dispatch({ "type": constants.FETCHED_RESULTS, "payload": resData });
      return resData;
    })
  } catch (err) {
    dispatch(setLoading(false));
    throw (err);
  }
}, constants.FETCH_ERROR);

export const postMessage = (payload) => wrap((dispatch) => {
  try {
    dispatch(setLoading(true));

    return apiCaller('POST', `contact/messages/`, payload).then((resData) => {
      dispatch(setLoading(false));
      dispatch({ "type": constants.POSTED_MESSAGE, "payload": resData });
      return resData;
    })
  } catch (err) {
    dispatch(setLoading(false));
    throw (err);
  }
}, constants.FETCH_ERROR);


// handling errors from redux-thunk async action creators
// source: https://thecodebarbarian.com/async-await-with-react-and-redux-thunk
export const wrap = (fn, type = '') => {
  return function (dispatch, getState) {
    return fn(dispatch, getState).catch((error) => {
      const firstKey = Object.keys(error)[0];
      dispatch({ type, error: error.message });
      dispatch(setLoading(false));
      // eslint-disable-next-line
      toastr.error(`Etwas ist schief gelaufen!`, firstKey + ": " + error[Object.keys(error)[0]], { timeOut: 0 });
    });
  };
}



