import { defineMessages } from "react-intl"

export default defineMessages({
  settings: {
    id: 'navigation.settings',
    defaultMessage: 'Settings'
  },
  lebenszeitberechnung: {
    id: 'navigation.lebenszeitberechnung',
    defaultMessage: 'Lebenszeitberechnung'
  },
  resultsTitle: {
    id: 'navigation.resultsTitle',
    defaultMessage: 'Your Calculation'
  },
  title: {
    id: 'bauwerk.title',
    defaultMessage: 'Bauwerk'
  },
  concreteType: {
    id: 'navigation.concreteType',
    defaultMessage: 'Concrete type'
  },
  exposition: {
    id: 'bauteil.exposition',
    defaultMessage: 'Exposition'
  },
  concreteThickness: {
    id: 'navigation.concreteThickness',
    defaultMessage: 'Concrete Thickness'
  },
  expositionExpert: {
    id: 'bauteil.expositionExpert',
    defaultMessage: 'Exposure Cs (Chlorideexposition)'
  },
  concreteThicknessExpert: {
    id: 'navigation.concreteThicknessExpert',
    defaultMessage: 'c<sub>nom</sub> , μ [mm]'
  },
  concreteThicknessSigma: {
    id: 'navigation.concreteThicknessSigma',
    defaultMessage: 'c<sub>nom</sub> , σ [mm]'
  },
  diffussionCoeff: {
    id: 'navigation.diffussionCoeff',
    defaultMessage: 'D<sub>RCM</sub> , μ [10-12 m²/s]'
  },
  diffussionCoeffStdev: {
    id: 'navigation.diffussionCoeffStdev',
    defaultMessage: ''
  },
  chlorideExpositionMean: {
    id: 'navigation.chlorideExpositionMean',
    defaultMessage: ''
  },
  chlorideExpositionStdev: {
    id: 'navigation.chlorideExpositionStdev',
    defaultMessage: ''
  },
  concreteCoverage: {
    id: 'navigation.concreteCoverage',
    defaultMessage: 'Convection depth Δx [mm]'
  },
  corrosionProbability: {
    id: 'navigation.corrosionProbability',
    defaultMessage: 'Probability of corrosion [%]'
  },
  inspectability: {
    id: 'bauteil.inspectability',
    defaultMessage: 'Inspectability'
  },
  inspectabilityGood: {
    id: 'bauteil.good',
    defaultMessage: 'Good'
  },
  inspectabilityBad: {
    id: 'bauteil.bad',
    defaultMessage: 'Bad'
  },
  reset: {
    id: 'navigation.reset',
    defaultMessage: 'Reset'
  },
  exportFile: {
    id: 'navigation.exportCSV',
    defaultMessage: 'Export CSV file'
  },
  createNew: {
    id: 'navigation.createNew',
    defaultMessage: 'Create new'
  },
  en: {
    id: 'en',
    defaultMessage: 'English'
  },
  de: {
    id: 'de',
    defaultMessage: 'German'
  },
  fr: {
    id: 'fr',
    defaultMessage: 'French'
  },
  it: {
    id: 'it',
    defaultMessage: 'Italian'
  },
  ccrit_c_mu: {
    id: "ccrit_c_mu",
    //defaultMessage: "Ccrit<sub>B500B</sub>, λ [M.-%/z]"
    //defaultMessage: "Kritischer korrosionsauslösender Chloridgehalt Ccrit für B500B (Mittelwert LogN) [M.-%/z]"
    defaultMessage: 'Ccrit<sub>Steel A</sub>, μ [M.-%/z]'
  },
  ccrit_c_sigma: {
    id: "ccrit_c_sigma",
    //defaultMessage: "Ccrit<sub>B500B</sub>, ε [M.-%/z]"
    //defaultMessage: "Kritischer korrosionsauslösender Chloridgehalt Ccrit für B500B (Standardabweichung LogN) [M.-%/z]"
    defaultMessage: 'Ccrit<sub>Steel A</sub>, σ [M.-%/z]'
  },
  ccrit_t_mu: {
    id: "ccrit_t_mu",
    //defaultMessage: "Ccrit<sub>Top12</sub>, λ [M.-%/z]"
    //defaultMessage: "Kritischer korrosionsauslösender Chloridgehalt Ccrit für Top12 (Mittelwert LogN) [M.-%/z]"
    defaultMessage: 'Ccrit<sub>Steel B</sub>, μ [M.-%/z]'
  },
  ccrit_t_sigma: {
    id: "ccrit_t_sigma",
    //defaultMessage: "Ccrit<sub>Top12</sub>, ε [M.-%/z]"
    //defaultMessage: "Kritischer korrosionsauslösender Chloridgehalt Ccrit für Top12 (Standardabweichung LogN) [M.-%/z]"
    defaultMessage: 'Ccrit<sub>Steel B</sub>, σ [M.-%/z]'
  },
  n_mu: {
    id: "n_mu",
    //defaultMessage: "Alterskoeffizient, μ [-]"
    defaultMessage: 'Aging exponent, μ [-]'
  },
  n_sigma: {
    id: "n_sigma",
    //defaultMessage: "Alterskoeffizient, σ [-]",
    defaultMessage: 'Aging exponent, σ [-]'
  }
})