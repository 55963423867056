import React, { useState } from 'react';
import './FAQ.scss';
import { defineMessages } from 'react-intl';
import { RootState } from '../../reducers';
import * as actions from '../../actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Logo from '../../components/Logo';
import classNames from 'classnames';
import LanguageSwitcher from "../../components/LanguageSwitcher";
import BackBtn from '../../components/BackBtn'
import KnowMoreCard from '../../components/KnowMoreCard/KnowMoreCard';

const translatableStrings = defineMessages({
  title: {
    id: 'faq.title',
    defaultMessage: 'Do you have any questions or suggestions for improvement?'
  },
  info: {
    id: 'faq.info',
    defaultMessage: 'We will answer your contributions personally in a timely manner. Questions relevant to the general public will be answered publicly on this website. In this way you will help us to and other users in the use of the application.'
  },
  btn: {
    id: 'faq.button',
    defaultMessage: 'Contact us'
  }
})

interface State {
}

interface Props {
  actions: any;
  history: any;
  userDataST: any;
  settings: any;
  intl: any;
}

function FaqSection() {
  const [expanded, setExpanded] = useState(false)
  return <div className={`faqSection ${expanded ? 'faqSectionExpanded' : ''}`} onClick={() => setExpanded(!expanded)}>
    <h5 className="faqSectionTitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h5>
    <div className={"faqSectionContent"}>
      <p className={"faqSectionText"}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
      </p>
      <p className={"faqSectionText"}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
      </p>
    </div>
  </div>
}

class FAQ extends React.Component<Props, State> {
  password: null;

  constructor(props: any) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    document.body.classList.add('standortPage');
  }

  componentWillUnmount() {
    document.body.classList.remove('standortPage');
  }

  render() {
    return (
      <div className="pageContainer">
        <div className="mobileHeader">
          <Logo />
        </div>
        <div className={classNames("sidebar", { 'visible': true, 'nonResultsPage': true })}>
          <div className="d-none d-sm-block">
            <Logo />
          </div>
          <LanguageSwitcher isExpertMode={false} isMobileMenu={false} intl={this.props.intl} />
        </div>
        <div className="main">
          <div className="mainBg p-relative">
            <BackBtn />
            <div className="header36 text-center stagger">FAQs</div>
            <div className="mt-5">
              <KnowMoreCard intl={this.props.intl} 
                title={translatableStrings.title} 
                text={translatableStrings.info} 
                button={translatableStrings.btn}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    ...state.app,
  }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);