import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions';
import './Standort.scss';
import SelectList, { flags } from '../../components/Selectlist/Selectlist';
import FormattedMessageCustom from '../../components/FormattedMessageCustom';
import { defineMessages } from 'react-intl';
import { ReactComponent as IconCheckmark } from '../../assets/icons/checkmark.svg';
import { animateOutAndNavigate } from '../../utils/react';
import classNames from 'classnames';
import { Button, EButtonType } from '../../components/Button';
import { defined } from '../../utils/variableEvaluation';
import LanguageSwitcher from "../../components/LanguageSwitcher";
import { setCalculateParameter, setSettingLabels } from "../../actions";

const translatableStrings = defineMessages({
  title: {
    id: 'standort.title',
    defaultMessage: 'Projektstandort'
  },
  subtitle: {
    id: 'standort.subtitle',
    defaultMessage: 'Wählen Sie Ihren Standort'
  },
  next: {
    id: 'navigation.next',
    defaultMessage: 'Weiter'
  }
})

interface State {
  standort: string;
  hovered: boolean;
  calculateParameters: any
  settingLabels: any
}

interface Props {
  actions: any;
  history: any;
  userDataST: any;
  settings: any;
  setUserDataStandort: any;
  setCalculateParameter: any;
  setSettingLabels: any;
  intl: any;
}

class Standort extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.firstInput = React.createRef();
    this.state = {
      //standort: this.props.userDataST.USERDATA_STANDORT,
      standort: null,
      calculateParameters: this.props.userDataST.CALCULATE_PARAMETER,
      settingLabels: this.props.userDataST.SETTING_LABELS,
      hovered: false
    }
  }

  private firstInput = null;

  componentDidMount() {
    document.body.classList.add('standortPage');
    document.addEventListener('keyup', this.handlePresses);
  }

  componentWillUnmount() {
    document.body.classList.remove('standortPage');
    document.removeEventListener('keyup', this.handlePresses);
  }


  handlePresses = (e) => {
    if (e.code === "ArrowRight") {
      this.setState({
        standort: 'ch'
      }, () => {
        this.firstInput.current.focus();
      })

      document.removeEventListener('keyup', this.handlePresses);
    } else if (e.code === "Tab") {
      document.removeEventListener('keyup', this.handlePresses);
      this.setState({
        standort: 'ch'
      });
    }
  }

  choose = (e) => {
    this.props.setUserDataStandort(this.state.standort);
    let parameters = { ...this.state.calculateParameters };

    if (this.state.standort.toLowerCase() === 'ch') {
      parameters.d_mu = 10;
      parameters.d_sigma = 1.5;
    } else {
      parameters.d_mu = null;
      parameters.d_sigma = null;
    }

    this.props.setCalculateParameter(parameters);
    let settingLabels = { ...this.state.settingLabels };
    settingLabels.country = this.props.settings.options['LOC'][this.state.standort].value.label;

    this.props.setSettingLabels(settingLabels);
  }

  onNextClick = (e) => {
    animateOutAndNavigate(() => {
      this.props.history.push({
        pathname: `/bauwerk`
      });
    })
  }


  render() {
    const onMouseProps = {
      onMouseEnter: (e) => {
        this.setState({
          hovered: true
        })
      },
      onMouseLeave: (e) => {
        this.setState({
          hovered: false
        })
      }
    }
    return (
      <div className="standortContainer pageContainer">
        <SelectList
          intl={this.props.intl}
        />
        <div className="main">
          <div className="mainBg">
            <FormattedMessageCustom id={translatableStrings.title.id}
              text={this.props.intl.formatMessage(translatableStrings.title)}>
              <div className="header36 text-center stagger"></div>
            </FormattedMessageCustom>
            <FormattedMessageCustom id={translatableStrings.subtitle.id}
              text={this.props.intl.formatMessage(translatableStrings.subtitle)}>
              <div className="title21 my-3 stagger"></div>
            </FormattedMessageCustom>
            <form className="text-center flexForm" onSubmit={(e) => {
              const tagName = (e.target as any).tagName;
              e.preventDefault();
              this.choose(e);
            }}>
              <div
                className={classNames("cards stagger mb-5", {
                  "hasActiveChild": this.state.standort || this.state.hovered
                })}>
                {this.props.settings.arr.map((country, idx) => {
                  return (
                    <fieldset
                      key={'countryInput_' + idx}
                    >
                      <input
                        ref={idx === 0 ? this.firstInput : this[country.name]}
                        type="radio"
                        id={country.name}
                        name="standort"
                        value={country.name}
                        onChange={(e) => {
                          this.setState({
                            standort: e.target.value
                          })
                        }}
                        checked={this.state.standort === country.name}
                      ></input>
                      <label
                        onClick={(e) => {
                          this.setState({
                            standort: country.name
                          }, () => this.choose(e))
                        }}
                        {...onMouseProps}
                        htmlFor={country.name} className={classNames("card nonButton", {})}
                      >
                        <div className="flagIcon">
                          {flags[country.name]}
                        </div>
                        <p className="country-label">{country.label}</p>
                        <span className="checkmark">
                          <IconCheckmark />
                        </span>
                      </label>
                    </fieldset>
                  )
                })}
              </div>
              {defined(this.state.standort) ? <Button
                className="m-auto"
                submit
                buttonType={EButtonType.PillMedium}
                isDisabled={!defined(this.state.standort)}
                onClick={(e) => {
                  this.onNextClick(e)
                }}
              >
                <FormattedMessageCustom id={translatableStrings.next.id}
                  text={this.props.intl.formatMessage(translatableStrings.next)}>
                  <span className=""></span>
                </FormattedMessageCustom>
              </Button> : null}
            </form>
          </div>
        </div>
        <div>
          <LanguageSwitcher isMobileMenu={true} intl={this.props.intl} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    ...state.app,
  }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    setUserDataStandort: (data) => dispatch(actions.setUserDataStandort(data)),
    setCalculateParameter: (data) => dispatch(actions.setCalculateParameter(data)),
    setSettingLabels: (data) => dispatch(actions.setSettingLabels(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Standort);
