import React from 'react';
import { connect } from 'react-redux';
import { RootState } from './reducers/index';
import { bindActionCreators, Dispatch } from 'redux';
import { Route, matchPath, Redirect } from 'react-router-dom'
import * as actions from './actions/';
import { TransitionGroup, Transition } from 'react-transition-group'
import gsap from 'gsap';
import Spinner from './components/Spinner';
import { animationConfig } from './constants';
import './App.scss';
import { injectIntl } from 'react-intl';
import Standort from './containers/Standort/Standort';
import Bauwerk from './containers/Bauwerk/Bauwerk';
import Bauteil from './containers/Bauteil/Bauteil';
import ConcreteType from './containers/ConcreteType/ConcreteType';
import Thickness from './containers/Thickness/Thickness';
import Visualisation from './containers/Visualisation/Visualisation';
import Modal from 'react-modal';
import Contact from './components/Contact/Contact';
import PDFReportPage from './containers/PDFReportPage/PDFReportPage';
import Login from "./containers/Login/Login";
import faq from "./containers/FAQ/FAQ"
import DisclaimerModal from './components/DisclaimerModal/DisclaimerModal';

export const routes = [
  {
    component: Standort,
    showInMenu: true,
    key: 'standort',
    path: '/standort',
    id: 'standort',
    exact: true,
    step: 1,
  },
  {
    component: Bauwerk,
    showInMenu: true,
    key: 'bauwerk',
    path: '/bauwerk',
    id: 'bauwerk',
    exact: true,
    step: 2,
  },
  {
    component: Bauteil,
    showInMenu: true,
    key: 'bauteil',
    path: '/bauteil',
    id: 'bauteil',
    exact: true,
    step: 3,
  },
  {
    component: ConcreteType,
    showInMenu: true,
    key: 'concreteType',
    path: '/concreteType',
    id: 'concreteType',
    exact: true,
    step: 4
  },
  {
    component: Thickness,
    showInMenu: true,
    key: 'thickness',
    path: '/thickness',
    id: 'thickness',
    exact: true,
    step: 5
  },
  {
    component: Visualisation,
    showInMenu: true,
    key: 'results',
    path: '/results',
    id: 'results',
    exact: true,
    step: 6
  },
  {
    component: Login,
    showInMenu: true,
    key: 'login',
    path: '/login',
    id: 'login',
    exact: true,
    step: 11
  }, {
    component: Visualisation,
    showInMenu: true,
    key: 'expert',
    path: '/expert',
    id: 'expert',
    exact: true,
    is_protected: true,
    step: 6
  },
  {
    component: PDFReportPage,
    showInMenu: true,
    key: 'pdf',
    path: '/pdf',
    id: 'pdf',
    exact: true,
    step: 10
  },
  {
    component: faq,
    showInMenu: true,
    key: 'faq',
    path: '/faq',
    id: 'faq',
    exact: true,
    step: 12
  }
]

const routesObj = {};
routes.forEach((route) => {
  routesObj[route.path] = route;
});

/**
 * filterRoutes returns true or false and compares
 * location.pathname with the path key
 * in each Object declared above in the routes Array.
 */
const filterRoutes = (location) => {
  return routes.filter(({ path, exact }) => {
    return !!matchPath(location.pathname, {
      path,
      exact
    })
  })
}

interface State {
  disclaimerAccepted: boolean;
}

interface Props {
  history: any;
  location: any;
  loading: boolean;
  userDataST: any;
  settings: any;
  intl: any;
  actions: any;
}

Modal.setAppElement('#root');

class Routes extends React.Component<Props, State> {
  private timelineIn = null;

  constructor(props) {
    super(props)
    const disclaimerAccepted = !!(localStorage.getItem('disclaimerAccepted')) || false;
    this.state = {
      disclaimerAccepted
    };
    this.props.actions.fetchTranslations();
  }  

  playIn = (path, node, appears?) => {
    this.timelineIn = gsap.timeline({ paused: true });
    this.timelineIn && this.timelineIn.pause();
    node.classList.remove('d-none');

    if (node.querySelectorAll('.stagger').length) {
      this.timelineIn.to(document.querySelectorAll('.stagger'), {
        autoAlpha: 1,
        stagger: { ...animationConfig().stagger },
      });

      this.timelineIn.play();
    }
  }

  componentDidMount() {
    this.props.actions.fetchSettings();
    this.props.actions.fetchVariables();
  }

  playOut = (path, node) => {
    node.classList.add('d-none');
  }

  acceptDisclaimer = () => {
    this.setState({ disclaimerAccepted: true })
    localStorage.setItem('disclaimerAccepted', JSON.stringify(true));
  }

  render() {
    const intl = this.props.intl;

    return (
      <div className="routeContainer">
        <div className="animationMask"></div>
        {this.props.loading && <Spinner />}
        <Route render={({ location, history }) => {
          const path = `/${location.pathname.split('/')[1]}`;
          if (!filterRoutes(location).length) {
            return (
              <Redirect to="/standort" />
            )
          }
          if (routes.find(r => r.path === path).is_protected) {
            const isAuthenticated = localStorage.getItem("isAuthenticated");
            if (!isAuthenticated) {
              return (
                <Redirect to="/login" />
              )
            }
          }

          return (<>
            <TransitionGroup appear key="transition1">
              {filterRoutes(location).map(({ key, ...props }) => {
                return this.props.settings && <Transition
                  key={'child-' + key}
                  timeout={{ enter: 0, exit: 0 }}
                  onEnter={(node, appears?) => this.playIn(path, node, appears)}
                  onExit={(node?) => this.playOut(path, node)}
                >
                  {React.createElement(routes.find(r => r.path === path).component, {
                    ...props,
                    location,
                    history,
                    intl
                  }, null)}
                </Transition>
              })}
            </TransitionGroup>
            {history.location.search.includes('modal=contact') && <Modal
              key='modal'
              isOpen={true}
              onRequestClose={() => history.push({ search: '' })}
              shouldCloseOnOverlayClick={true}
              className={"large-modal modal"}
              closeTimeoutMS={500}
              overlayClassName={"overlay"}
            >
              <Contact
                {...this.props}
                intl={intl}
                onClose={() => {
                  history.push({ search: history.location.search.split('&')[0].split('modal=contact')[0] })
                }}
              />
            </Modal>}
            {!this.state.disclaimerAccepted && <Modal
              key='modal-disclaimer'
              isOpen={true}
              onRequestClose={() => this.acceptDisclaimer()}
              shouldCloseOnOverlayClick={true}
              className={"large-modal large-modal-half modal"}
              closeTimeoutMS={500}
              overlayClassName={"overlay"}
            >
              <DisclaimerModal {...this.props} 
                onClose={() => this.acceptDisclaimer()}
              />
            </Modal>}
          </>)
        }} />
      </div>
    )
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    ...state.app,
  }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    actions: bindActionCreators(actions as any, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Routes));
