import FormattedMessageCustom from '../FormattedMessageCustom';
import {ReactComponent as IconInfo } from '../../assets/icons/info.svg';
import ReactTooltip from 'react-tooltip';

type Props = {
  inputType: string;
  messageId: string;
  message: string;
  onChange: Function;
  value?: string|number;
  tooltipMsg?: string;
  defaultValue?: string;
}

function FieldTooltip({ msg, id }: { msg: string; id: string }) {
  return <ReactTooltip id={id} className="expert-tooltip" backgroundColor="#444" textColor="#fff">
      <span>{msg}</span>
  </ReactTooltip>
}

function ExpertModeField ({ 
  inputType = 'number', 
  messageId,
  message,
  onChange, 
  value = '', 
  defaultValue, 
  tooltipMsg }: Props) {
  const tooltipId = `expert-tooltip-${messageId}`
  const inputs = {} as any
  defaultValue ? (inputs.defaultValue = defaultValue) : (inputs.value = value)
  return <>
    <div className={"inputWrap"} data-for={tooltipId} data-tip>
      <FormattedMessageCustom
        id={messageId}
        text={message}>
        <div className="inputTitle"></div>
      </FormattedMessageCustom>
      <IconInfo />
    </div>

    {tooltipMsg && <FieldTooltip msg={tooltipMsg} id={tooltipId} />}
    
    <input
      type={inputType}
      className="customInput"
      onChange={(e) => {
        onChange(e.target.value)
      }}
      {...inputs}
    />
  </>
}

export default ExpertModeField