import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions';
import './Bauwerk.scss';
import SelectList from '../../components/Selectlist/Selectlist';
import FormattedMessageCustom from '../../components/FormattedMessageCustom';
import { defineMessages } from 'react-intl';
import { ReactComponent as IconCheckmark } from '../../assets/icons/checkmark.svg';
import { animateOutAndNavigate } from '../../utils/react';
import classNames from 'classnames';
import { Button, EButtonType } from '../../components/Button';
import { defined } from '../../utils/variableEvaluation';
import LanguageSwitcher from "../../components/LanguageSwitcher";
//import {backgroundImage} from "html2canvas/dist/types/css/property-descriptors/background-image";
import BackBtn from '../../components/BackBtn'

const translatableStrings = defineMessages({
  title: {
    id: 'bauwerk.title',
    defaultMessage: 'Bauwerk'
  },
  subtitle: {
    id: 'bauwerk.subtitle',
    defaultMessage: 'Choose your construction type'
  },
  bridge: {
    id: 'bauwerk.bridge',
    defaultMessage: 'Brücke'
  },
  tunnel: {
    id: 'bauwerk.tunnel',
    defaultMessage: 'Tunnel'
  },
  parken: {
    id: 'bauwerk.parken',
    defaultMessage: 'Parken'
  },
  next: {
    id: 'navigation.next',
    defaultMessage: 'Weiter'
  }
})

interface State {
  bauwerk: string;
  bauwerkData: any;
  hovered: boolean;
  settingLabels: any;
}

interface Props {
  actions: any;
  history: any;
  userDataST: any;
  settings: any;
  setUserDataBauwerk: any;
  setUserDataBauwerkData: any;
  setSettingLabels: any;
  intl: any;
}

class Bauwerk extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.firstInput = React.createRef();
    this.state = {
      bauwerk: null,
      settingLabels: this.props.userDataST.SETTING_LABELS,
      hovered: false,
      bauwerkData: this.props.userDataST.USERDATA_BAUWERK
    }
  }

  private firstInput = null;

  componentDidMount() {
    document.body.classList.add('bauwerkPage');
    document.addEventListener('keyup', this.handlePresses);
  }

  componentWillUnmount() {
    document.body.classList.remove('bauwerkPage');
    document.removeEventListener('keyup', this.handlePresses);
  }

  handlePresses = (e) => {
    if (e.code === "ArrowRight") {
      this.setState({
        bauwerk: 'ch'
      }, () => {
        this.firstInput.current.focus();
      })

      document.removeEventListener('keyup', this.handlePresses);
    } else if (e.code === "Tab") {
      document.removeEventListener('keyup', this.handlePresses);
      this.setState({
        bauwerk: 'ch'
      });
    }
  }

  choose = (e) => {
    this.props.setUserDataBauwerk(this.state.bauwerk);
    this.props.setUserDataBauwerkData(this.state.bauwerkData);
    let settingLabels = { ...this.state.settingLabels };
    settingLabels.building = this.state.bauwerkData.label;
    this.props.setSettingLabels(settingLabels);

    animateOutAndNavigate(() => {
      this.props.history.push({
        pathname: `/bauteil`
      });
    })
  }

  render() {
    const onMouseProps = {
      onMouseEnter: (e) => {
        this.setState({
          hovered: true
        })
      },
      onMouseLeave: (e) => {
        this.setState({
          hovered: false
        })
      }
    }
    return (
      <div className="bauwerkContainer pageContainer">
        <SelectList
          intl={this.props.intl}
        />
        <div className="main">
          <div className="mainBg p-relative">
            <BackBtn pathname="/" />
            <FormattedMessageCustom id={translatableStrings.title.id}
              text={this.props.intl.formatMessage(translatableStrings.title)}>
              <div className="header36 text-center stagger"></div>
            </FormattedMessageCustom>
            <FormattedMessageCustom id={translatableStrings.subtitle.id}
              text={this.props.intl.formatMessage(translatableStrings.subtitle)}>
              <div className="title21 my-3 stagger"></div>
            </FormattedMessageCustom>
            <form className="text-center flexForm" onSubmit={(e) => {
              e.preventDefault();
              this.choose(e);
            }}>
              <div
                className={classNames("cards stagger", {
                  "hasActiveChild": this.state.bauwerk || this.state.hovered
                })}>

                {this.props.settings.obj[this.props.userDataST.USERDATA_STANDORT].CON.map((contructionType, idx) => {
                  // console.log(this.props.userDataST.USERDATA_STANDORT)
                  return (
                    <fieldset
                      key={'countryInput_' + idx}
                    >
                      <input
                        ref={idx === 0 ? this.firstInput : this[contructionType.name]}
                        type="radio"
                        id={contructionType.name}
                        name="bauwerk"
                        value={contructionType.name}
                        onChange={(e) => {
                          this.setState({
                            bauwerk: e.target.value
                          })
                        }}
                        checked={this.state.bauwerk === contructionType.name}
                      />
                      <label
                        onClick={(e) => {
                          this.setState({
                            bauwerk: contructionType.name,
                            bauwerkData: contructionType,
                          })
                        }}
                        htmlFor={contructionType.name}
                        {...onMouseProps}
                        className="card nonButton"
                        style={{
                          backgroundImage: 'url("' + contructionType.image + '")'
                        }}
                      >
                        <span className="checkmark">
                          <IconCheckmark />
                        </span>
                        <p className="">{contructionType.label}</p>
                      </label>
                    </fieldset>
                  )
                })}
              </div>
              {
                defined(this.state.bauwerk) ? <Button
                  className="mt-5"
                  submit
                  buttonType={EButtonType.PillMedium}
                  isDisabled={!defined(this.state.bauwerk)}
                  onClick={(e) => {
                    this.choose(e)
                  }}
                >
                  <FormattedMessageCustom id={translatableStrings.next.id}
                    text={this.props.intl.formatMessage(translatableStrings.next)}>
                    <span className=""></span>
                  </FormattedMessageCustom>
                </Button> : null
              }
            </form>
          </div>
        </div>
        <LanguageSwitcher isMobileMenu={true} intl={this.props.intl} />
      </div>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    ...state.app,
  }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    setUserDataBauwerk: (data) => dispatch(actions.setUserDataBauwerk(data)),
    setUserDataBauwerkData: (data) => dispatch(actions.setUserDataBauwerkData(data)),
    setSettingLabels: (data) => dispatch(actions.setSettingLabels(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bauwerk);
