import * as constants from '../constants';
import de from '../constants/locales/de.json';
import en from '../constants/locales/en.json';
import fr from '../constants/locales/fr.json';
import it from '../constants/locales/it.json';
import { flattenMessages } from '../utils/react';

const getInitialState = () => {
  return {
    en: flattenMessages(en),
    it: flattenMessages(it),
    de: flattenMessages(de),
    fr: flattenMessages(fr)
  }
};

const localesReducer = (state: any = getInitialState(), action: any) => {
  switch (action.type) {
    case constants.UPDATE_LOCALES: {
      alert("ueu");
      return { ...state, ...action.payload };
    }
  }
  return state;
};

export default localesReducer;
