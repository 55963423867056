import { ReactNode, useEffect } from 'react';
import { Document, Page, Text, Image, View, usePDF, Font } from '@react-pdf/renderer';
import logo from '../../assets/imgs/logo-new.png';
import helvetica from '../../assets/fonts/HelveticaNeue.woff';
import helveticaBold from '../../assets/fonts/HelveticaNeue-Bold.otf';
import IconCH from '../../assets/imgs/CH.png';
import IconDE from '../../assets/imgs/DE.png';
import IconUnknown from '../../assets/imgs/OTHER.png';
import Html from 'react-pdf-html';
import { defineMessages } from "react-intl"
import styles, { pxToPts } from './styles';

const translatableStrings = defineMessages({
  title: {
    id: 'visualisation.title',
    defaultMessage: 'Your Calculation'
  },
  bauwerk: {
    id: 'bauwerk.title',
    defaultMessage: ''
  },
  exposition: {
    id: 'bauteil.exposition',
    defaultMessage: 'Exposition'
  },
  inspectability: {
    id: 'bauteil.inspectability',
    defaultMessage: 'Inspectability'
  },
  concreteType: {
    id: 'navigation.concreteType',
    defaultMessage: 'Concrete type'
  },
  concreteThickness: {
    id: 'navigation.concreteThickness',
    defaultMessage: 'Concrete Thickness'
  },
  top12Steel: {
    id: 'concreteType.top12Steel',
    defaultMessage: 'Top 12 Steel'
  },
  blackSteel: {
    id: 'concreteType.blackSteel',
    defaultMessage: 'Black steel'
  },
  lebenszeitberechnung: {
    id: 'navigation.lebenszeitberechnung',
    defaultMessage: 'Lebenszeitberechnung'
  },
  years: {
    id: 'concreteType.years',
    defaultMessage: 'Jahre'
  },
  knowMoreTitle: {
    id: 'knowMoreCard.title',
    defaultMessage: ''
  },
  knowMoreDescription: {
    id: 'knowMoreCard.description',
    defaultMessage: ''
  },
  contactUs: {
    id: 'knowMoreCard.contactUs',
    defaultMessage: 'Contact Us'
  },
  disclaimerTitle: {
    id: 'disclaimer.title',
    defaultMessage: ''
  },
  disclaimerContent: {
    id: 'disclaimer.info',
    defaultMessage: ''
  }
});

// Register font
Font.register({
  family: "Helvetica",
  src: helvetica,
});
Font.register({
  family: "HelveticaBold",
  src: helveticaBold,
});
const flags = {
  'de': IconDE,
  'ch': IconCH,
  'OTHER': IconUnknown,
}

function SettingTitle({ text }: { text: string }) {
  return <Text style={{
    fontFamily: 'HelveticaBold',
    fontSize: pxToPts(16),
    color: '#000000',
    marginBottom: pxToPts(10)
  }}>{text}</Text>
}

function SettingsBlock({ title, content }: { title: string; content: ReactNode; }) {
  return <View style={styles.settingsSubSection}>
    <SettingTitle text={title} />
    <Text>{content}</Text>
  </View>
}

export const MyDoc = ({ intl, chartImage, userData, isExpert }: any) => {
  
  const SettingsDefault = () => {
    return <View style={styles.settingsSection}>      
      <Text style={styles.settingsTitle}>{intl.formatMessage(translatableStrings.title)}</Text>

      <View style={styles.settingsSubSection}>
        {
          userData?.USERDATA_STANDORT &&
          <Image src={flags[userData?.USERDATA_STANDORT]} style={{
            width: pxToPts(22.5),
            height: pxToPts(16),
            position: 'absolute',
            right: 0,
            top: 0
          }} />
        }
        <SettingTitle text={intl.formatMessage(translatableStrings.bauwerk)} />
        <Text>{userData?.SETTING_LABELS.building} | {userData?.SETTING_LABELS.component}</Text>
        <Text>
          {intl.formatMessage(translatableStrings.exposition)}: {userData?.SETTING_LABELS.exposition_class}
        </Text>
        <Text>
          {intl.formatMessage(translatableStrings.inspectability)}: {userData?.SETTING_LABELS.inspectability}
        </Text>
      </View>

      {userData?.USERDATA_STANDORT === 'de' &&
        <SettingsBlock title={intl.formatMessage(translatableStrings.concreteType)}
          content={<>{userData?.SETTING_LABELS.concrete_type_variant} ({userData?.SETTING_LABELS.concrete_type_wz_value})</>} 
        />
      }
      <SettingsBlock title={intl.formatMessage(translatableStrings.concreteThickness)}
        content={<>{userData?.SETTING_LABELS.thinkness} mm</>} 
      />
      <SettingsBlock title={intl.formatMessage(translatableStrings.exposition)}
        content={<>{userData?.SETTING_LABELS.exposition}</>}
      />
    </View>
  }

  const SettingsExpert = () => {
    const params = userData.EXPERT_MODE_CALCULATE_PARAMETER
    return <View style={styles.settingsSection}>
      <Text style={styles.settingsTitle}>
        Your Calculation
      </Text>
      <SettingsBlock title="cnom , μ [mm]" 
        content={<>{params.x_mu}</>}
      />
      <SettingsBlock title="cnom , σ [mm]"
        content={<>{params.x_sigma}</>}
      />
      <SettingsBlock title="DRCM , μ [10-12 m²/a]"
        content={<>{params.d_mu}</>}
      />
      <SettingsBlock title="DRCM , σ [10-12 m²/a]"
        content={<>{params.d_sigma}</>}
      />
      <SettingsBlock title="CS,∆x , μ [M.-%/z]"
        content={<>{params.cs_mu}</>}
      />
      <SettingsBlock title="CS,∆x , σ [M.-%/z]"
        content={<>{params.cs_sigma}</>}
      />
      <SettingsBlock title="Probability of corrosion [%]"
        content={<>{params.inspectability}</>}
      />
      <SettingsBlock title="CcritSteel A, μ [M.-%/z]"
        content={<>{params.ccrit_c_mu}</>}
      />
      <SettingsBlock title="CcritSteel A, σ [M.-%/z]"
        content={<>{params.ccrit_c_sigma}</>}
      />
      <SettingsBlock title="CcritSteel B, μ [M.-%/z]"
        content={<>{params.ccrit_t_mu}</>}
      />
      <SettingsBlock title="CcritSteel B, σ [M.-%/z]"
        content={<>{params.ccrit_t_sigma}</>}
      />
      <SettingsBlock title="Aging exponent, μ [-]"
        content={<>{params.n_mu}</>}
      />
      <SettingsBlock title="Aging exponent, σ [-]"
        content={<>{params.n_sigma}</>}
      />
    </View>
  }

  const FindOutMore = () => {
    return <View style={isExpert ? styles.findOutMoreSectionExpert :  styles.findOutMoreSection}>
      <Text style={styles.findOutMore}>{intl.formatMessage(translatableStrings.knowMoreTitle)}</Text>
      <Html style={styles.findOutMore2}>
        {intl.formatMessage(translatableStrings.knowMoreDescription)}
      </Html>
    </View>
  }

  const Disclaimer = () => {
    return <View style={isExpert ? styles.findOutMoreSectionDisclaimerExpert :  styles.findOutMoreSectionDisclaimer}>
      <Text style={styles.findOutMore}>{intl.formatMessage(translatableStrings.disclaimerTitle)}</Text>
      <Text style={styles.findOutMore2}>{intl.formatMessage(translatableStrings.disclaimerContent)}</Text>
    </View>
  }

  const Chart = () => {
    return <View style={styles.chartSection}>
      <View style={styles.chart}>
        <Image style={styles.chartImage}
          source={chartImage || JSON.parse(localStorage.getItem('chartImage'))} />
      </View>
      <View style={styles.pill}>
        <Text>{isExpert ? 'Steel A' : intl.formatMessage(translatableStrings.blackSteel)}</Text><Text
          style={{
            position: 'absolute',
            right: pxToPts(26),
            top: pxToPts(22),
            color: '#666666'
          }}>{userData?.betaCYear}&nbsp;
          {intl.formatMessage(translatableStrings.years)}</Text>
      </View>
      <View
        style={[styles.pill, { backgroundColor: 'rgba(230, 51, 35, 0.1)', marginLeft: pxToPts(26) }]}>
        <Text>{isExpert ? 'Steel B' : intl.formatMessage(translatableStrings.top12Steel)}</Text><Text
          style={{
            position: 'absolute',
            right: pxToPts(26),
            top: pxToPts(22),
            color: '#E63323'
          }}>{userData?.betaTYear}&nbsp;
          {intl.formatMessage(translatableStrings.years)}</Text>
      </View>
    </View>
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>

        <View style={styles.header}>
          <Image src={logo} style={styles.logo} />
          <Text style={styles.headerText}>
            {intl.formatMessage(translatableStrings.lebenszeitberechnung)}
          </Text>
        </View>

        <View style={styles.wrapper}>
          {isExpert ? <SettingsExpert /> : <SettingsDefault />}
          <View style={styles.mainSection}>
            <Chart />
            {isExpert && <Disclaimer />}
            {isExpert && <FindOutMore />}
          </View>
        </View>
        {!isExpert && <Disclaimer />}
        {!isExpert && <FindOutMore />}
      </Page>
    </Document>
  )
};

export const ReportFileLink = ({ chartImage, userData }: any) => {
  const [instance, updateInstance] = usePDF({ document: <MyDoc chartImage={chartImage} userData={userData} /> });

  useEffect(() => {
    updateInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartImage]);
  
  // if (instance.loading) return <div>Loading ...</div>;
  // if (instance.error) return <div>Something went wrong: {instance.error}</div>;

  return (
    <a href={instance.url} download="test.pdf" className="mt-0 button invertedPill d-flex align-items-center" data-html2canvas-ignore>
      Download PDF
    </a>
  );
}
