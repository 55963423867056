import React from 'react';
import { ReactComponent as IconLanguage } from "../../assets/icons/language.svg";
import { ReactComponent as IconArrowGrayDown } from "../../assets/icons/arrow-down-gray.svg";
import Select from 'react-select';
import { defineMessages } from "react-intl";
import { Dispatch } from "redux";
import { RootState } from "../../reducers";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import "./LanguageSwitcher.scss"
import FormattedMessageCustom from '../FormattedMessageCustom';

interface Props {
  clearData: any;
  currentStep: number;
  intl: any;
  results?: boolean;
  expert?: boolean;
  visible?: boolean;
  userDataST: any;
  createNewChart: Dispatch<any>;
  changeLocale: any;
  isMobileMenu: boolean;
  isExpertMode?: boolean;
}

const translatableStrings = defineMessages({
  en: {
    id: 'en',
    defaultMessage: 'English'
  },
  de: {
    id: 'de',
    defaultMessage: 'Deutsch'
  },
  fr: {
    id: 'fr',
    defaultMessage: 'Français'
  },
  it: {
    id: 'it',
    defaultMessage: 'Italiano'
  },
  manual: {
    id: 'navigation.manual',
    defaultMessage: 'Manual'
  }
})

const options = [
  { value: 'en', label: 'English' },
  { value: 'de', label: 'Deutsch' },
  { value: 'fr', label: 'Français' },
  { value: 'it', label: 'Italiano' }
];

const pdfEn = './20221027_Manual_EN_UA.pdf'
const pdfDe = './20221013_Handbuch_DE_1.pdf'

class LanguageSwitcher extends React.Component<Props> {
  private selectedLocale: {};

  constructor(props: any) {
    super(props);
    this.selectedLocale = {}
    let locale = this.props.intl.locale;
    if (!translatableStrings[this.props.intl.locale]) locale = 'en';

    if (this.props.intl && this.props.intl.locale) {
      this.selectedLocale = {
        'value': this.props.intl?.locale,
        'label': this.props.intl?.formatMessage({
          id: translatableStrings[locale].id,
          text: translatableStrings[locale]
        })
      }
    }
  }

  downloadManual() {
    let file = pdfEn;
    console.log(this)
    if (this.props.intl?.locale === 'de') file = pdfDe;
    const link = document.createElement('a');
    link.href = `${file}`;
    link.download = file;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  render() {
    return <div className={`language-switcher-container
      ${this.props.isMobileMenu ? 'mobile-language-switcher-container' : 'desktop-language-switcher-container'}
      ${this.props.isExpertMode ? 'language-switcher-container_expert' : ''}
    `}>
      <div className="language-switcher-wrap">
        <div className="language-switcher-faq-wrapper">
          <Link to="/faq" className="language-switcher-faq">FAQs</Link>
          <span className="language-switcher-separator">|</span>
        </div>

        <div className={this.props.isMobileMenu ? 'mobile-wrapper' : 'language-switcher-wrapper'}>
          <IconLanguage />
          <Select
            isSearchable={false}
            isDisabled={this.props.isExpertMode}
            menuPlacement="top"
            components={{ 'DropdownIndicator': () => (this.props.isExpertMode ? null : <IconArrowGrayDown />) }}
            value={this.props.isExpertMode ? { value: 'en', label: 'English' } : this.selectedLocale}
            styles={{
              control: (base) => ({
                ...base,
                boxShadow: "none",
                cursor: "pointer"
              }),
              valueContainer: (provided) => ({
                ...provided,
                padding: 15,
                cursor: "pointer"
              }),
              option: (provided) => ({
                ...provided,
                padding: 15,
                cursor: "pointer"
              })
            }}
            placeholder="Language"
            className="dropdownInput"
            onChange={(e) => {
              (window as any).localStorage.setItem("locale", e.value);
              window.location.reload();
            }}
            options={this.props.isExpertMode ? [{ value: 'en', label: 'English' }] : options}
          />
        </div>
      </div>

      <div className="manual-link">
        <FormattedMessageCustom id={translatableStrings.manual.id} text={this.props.intl.formatMessage(translatableStrings.manual)}>
          <span onClick={() => this.downloadManual()} className="language-switcher-faq"></span>
        </FormattedMessageCustom>
      </div>
    </div>;
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    ...state.app,
  }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    changeLocale: (data) => dispatch(actions.changeLocale(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
