import { useHistory } from 'react-router-dom'
import { ReactComponent as IconArrowLeft } from '../../assets/icons/arrow-left.svg';
import styles from './BackBtn.module.scss'

function BackBtn({ pathname = null, onClick = () => {} }) {
  const history = useHistory()
  
  return <button className={`${styles.backBtn} nonButton`} onClick={() => {
    onClick()
    if (pathname) {
      history.push({ pathname })
    } else {
      history.goBack()
    }
  }}>
    <IconArrowLeft />
  </button>;
}

export default BackBtn;