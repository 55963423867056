import { Button, EButtonType } from "../Button";
import FormattedMessageCustom from "../FormattedMessageCustom";
import {ReactComponent as IconX} from '../../assets/icons/x.svg';
import { defineMessages } from "react-intl";

const translatableStrings = defineMessages({
  title: {
    id: 'disclaimer.title',
    defaultMessage: 'Disclaimer'
  },
  content: {
    id: 'disclaimer.info',
    defaultMessage: 'This online calculator is not a substitute for consulting a qualified professional. This online calculator is provided primarily for illustrative and educational purposes. Results obtained may vary depending on the accuracy of the input data provided when using the calculator. The developers and Steeltec accept no liability whatsoever for any losses or liabilities allegedly arising from the use of the online calculator by any person or company. The developers and Steeltec accept do not warrant, guarantee, or make any representations regarding the use, or the results of use, of these calculators or accompanying information in terms of its accuracy, validity, completeness, applicability, or compliance with any regulatory code or governmental laws and regulations.'
  },
  btn: {
    id: 'disclaimer.buttonLabel',
    defaultMessage: 'Consent'
  },
})

function DisclaimerModal({ onClose, history, intl }) {
  return <div className="modalContainer" id="root">
    {/*<div className="closeIcon">
      <Button onClick={() => onClose && onClose()}>
        <IconX />
      </Button>
    </div>*/}
    <FormattedMessageCustom id={translatableStrings.title.id}
      text={intl.formatMessage(translatableStrings.title)}>
      <p className="font-500 font-24"></p>
    </FormattedMessageCustom>
    
    <FormattedMessageCustom id={translatableStrings.content.id}
      text={intl.formatMessage(translatableStrings.content)}>
      <p className="font-400 font-14"></p>
    </FormattedMessageCustom>
    
    <Button
      className="mt-1"
      buttonType={EButtonType.InvertedPill}
      onClick={() => onClose && onClose()}
    >
      <FormattedMessageCustom id={translatableStrings.btn.id}
        text={intl.formatMessage(translatableStrings.btn)}>
        <span className=""></span>
      </FormattedMessageCustom>
    </Button>
  </div>
}

export default DisclaimerModal;