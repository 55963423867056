import * as constants from '../constants/index';
import { addToLSObject } from '../utils/react';
import { routes } from '../Routes';
import { uniqueId } from 'lodash';

export interface AppState {
  currentStep: number;
  errors: any;
  loading: boolean;
  settings: any;
  userDataST: any;
  userDataSTArchive: Array<any>;
}

const currentPage = window.location.hash ? window.location.hash.split('/')[1].split('?')[0] : "";
let currentStep;

routes.forEach((route) => {
  if (route.key == currentPage) {
    currentStep = route.step;
  }
});


(window as any).preloadData = () => {
  const data = {};
  localStorage.setItem('userDataST', JSON.stringify(data));
}

const userDataSettings = {
  'USERDATA_STANDORT': null,
  'USERDATA_BAUWERK': null,
  'USERDATA_BAUWERK_DATA': null,
  'USERDATA_BAUTEIL': { exposition: null },
  'USERDATA_CONCRETE_TYPE': null,
  'EXPOSITION_OPTIONS': {},
  'SETTING_LABELS': {
    country: '',
    building: '',
    component: '',
    exposition: '',
    exposition_class: '',
    inspectability: '',
    thinkness: '',
    concrete_type: '',
    concrete_type_variant: '',
    concrete_type_wz_value: '',
  },
  'CALCULATE_PARAMETER': {
    "x_mu": null,
    "x_sigma": 5,
    "d_mu": null,
    "d_sigma": null,
    "cs_mu": null,
    "cs_sigma": null,
    "inspectability": null
  },
  'EXPERT_MODE_CALCULATE_PARAMETER': {
    "x_mu": 55,
    "x_sigma": 5,
    "d_mu": 10,
    "d_sigma": 1.5,
    "cs_mu": 2.3,
    "cs_sigma": 0.5,
    "inspectability": 0.31,
    "ccrit_c_mu": 1.3,
    "ccrit_c_sigma": 0.18,
    "ccrit_t_mu": 2.34,
    "ccrit_t_sigma": 0.69,
    "n_mu": 0.4,
    "n_sigma": 0.1
  },
  cutoffPoint: 0,
};

const getInitialState = () => {
  let lsUserData;

  try {
    lsUserData = JSON.parse((window as any).localStorage.getItem("userDataST"));
  } catch (error) {
    lsUserData = null;
    console.warn('There was a problem with LS', error)
  }

  return {
    loading: false,
    errors: [],
    currentStep: currentStep,
    userDataSTArchive: [],
    settings: null,
    userDataST: {
      'USERDATA_STANDORT': null,
      'USERDATA_BAUWERK': null,
      'USERDATA_BAUWERK_DATA': null,
      'USERDATA_BAUTEIL': { exposition: null },
      'USERDATA_CONCRETE_TYPE': null,
      'EXPOSITION_OPTIONS': {},
      'SETTING_LABELS': {
        country: '',
        building: '',
        component: '',
        exposition: '',
        exposition_class: '',
        inspectability: '',
        thinkness: '',
        concrete_type: '',
        concrete_type_variant: '',
        concrete_type_wz_value: '',
      },
      'CALCULATE_PARAMETER': {
        "x_mu": null,
        "x_sigma": 6,
        "d_mu": null,
        "d_sigma": null,
        "cs_mu": null,
        "cs_sigma": null,
        "inspectability": null
      },
      'EXPERT_MODE_CALCULATE_PARAMETER': {
        "x_mu": 55,
        "x_sigma": 5,
        "d_mu": 10,
        "d_sigma": 1.5,
        "cs_mu": 2.3,
        "cs_sigma": 0.5,
        "inspectability": 0.31,
        "ccrit_c_mu": 1.3,
        "ccrit_c_sigma": 0.18,
        "ccrit_t_mu": 2.34,
        "ccrit_t_sigma": 0.69,
        "n_mu": 0.4,
        "n_sigma": 0.1
      },
      cutoffPoint: 0,
      ...lsUserData
    }
  }
};

const app = (state: AppState = getInitialState(), action: any) => {
  switch (action.type) {
    case constants.CLEAR_DATA: {
      window.localStorage.removeItem('userDataST');
      /*const initialState = {...getInitialState()};*/
      return {
        ...state,
        currentStep: 1,
        userDataST: userDataSettings
      }
    }
    case constants.LOADING: {
      return { ...state, loading: action.payload }
    }

    case constants.USERDATA_STANDORT: {
      addToLSObject("userDataST", constants.USERDATA_STANDORT, action.payload);
      return {
        ...state,
        currentStep: 2,
        userDataST: {
          ...state.userDataST,
          [constants.USERDATA_STANDORT]: action.payload,
        }
      }
    }

    case constants.USERDATA_BAUWERK: {
      addToLSObject("userDataST", [constants.USERDATA_BAUWERK], action.payload);
      return {
        ...state,
        currentStep: 2,
        userDataST: {
          ...state.userDataST,
          [constants.USERDATA_BAUWERK]: action.payload,
        }
      }
    }

    case constants.USERDATA_BAUWERK_DATA: {
      addToLSObject("userDataST", [constants.USERDATA_BAUWERK_DATA], action.payload);
      return {
        ...state,
        userDataST: {
          ...state.userDataST,
          [constants.USERDATA_BAUWERK_DATA]: action.payload,
        }
      }
    }

    case constants.USERDATA_BAUTEIL: {
      addToLSObject("userDataST", constants.USERDATA_BAUTEIL, action.payload);
      return {
        ...state,
        currentStep: 3,
        userDataST: {
          ...state.userDataST,
          [constants.USERDATA_BAUTEIL]: action.payload
        }
      }
    }

    case constants.CALCULATE_PARAMETER: {
      addToLSObject("userDataST", constants.CALCULATE_PARAMETER, action.payload);
      return {
        ...state,
        userDataST: {
          ...state.userDataST,
          [constants.CALCULATE_PARAMETER]: action.payload,
        }
      }
    }

    case constants.EXPOSITION_OPTIONS: {
      addToLSObject("userDataST", constants.EXPOSITION_OPTIONS, action.payload);
      return {
        ...state,
        userDataST: {
          ...state.userDataST,
          [constants.EXPOSITION_OPTIONS]: action.payload,
        }
      }
    }

    case constants.SETTING_LABELS: {
      addToLSObject("userDataST", constants.SETTING_LABELS, action.payload);
      return {
        ...state,
        userDataST: {
          ...state.userDataST,
          [constants.SETTING_LABELS]: action.payload,
        }
      }
    }

    case constants.USERDATA_CONCRETE_TYPE: {
      addToLSObject("userDataST", constants.USERDATA_CONCRETE_TYPE, action.payload);
      return {
        ...state,
        currentStep: 3,
        userDataST: {
          ...state.userDataST,
          [constants.USERDATA_CONCRETE_TYPE]: action.payload,
        }
      }
    }

    case constants.USERDATA_VARIANT: {
      addToLSObject("userDataST", constants.USERDATA_VARIANT, action.payload);
      return {
        ...state,
        currentStep: 2,
        userDataST: {
          ...state.userDataST,
          [constants.USERDATA_VARIANT]: action.payload,
        }
      }
    }

    case constants.USERDATA_THICKNESS: {
      addToLSObject("userDataST", constants.USERDATA_THICKNESS, action.payload);
      return {
        ...state,
        currentStep: 3,
        userDataST: {
          ...state.userDataST,
          [constants.USERDATA_THICKNESS]: action.payload,
        }
      }
    }

    case constants.CHANGE_USERDATA: {
      return {
        ...state,
        userDataST: { ...state.userDataSTArchive[action.payload] }
      }
    }

    case constants.INIT_CHART_ARCHIVE: {
      return {
        ...state,
        userDataSTArchive: [{ ...state.userDataST, key: uniqueId('userDataSTArchive') }]
      }
    }

    case constants.CREATE_NEW_CHART: {
      const userDataSTArchive = [...state.userDataSTArchive]
      if (!action.payload.isSilent) {
        const res = {
          ...state.userDataST,          
          key: uniqueId('userDataSTArchive')
        }
        if (action.payload.params.expert) res.EXPERT_MODE_CALCULATE_PARAMETER = action.payload.params.expert;
        if (action.payload.params.thickness) res.USERDATA_THICKNESS = action.payload.params.thickness;  
        if (action.payload.params.exposition) res.USERDATA_BAUTEIL = {
          ...state.userDataST.USERDATA_BAUTEIL,
          exposition: action.payload.params.exposition,
        };
        userDataSTArchive.push(res)
      }
      return {
        ...state,
        currentStep: 1,
        userDataST: { ...state.userDataST },
        userDataSTArchive
      }
    }

    case constants.FETCHED_RESULTS: {
      const betaC = action.payload.beta_C;
      const betaT = action.payload.beta_T;
      addToLSObject("userDataST", constants.USERDATA_RESULT, action.payload);

      let results = [];
      let betaCResult = [];
      let betaTResult = [];
      let betaTYear = null;
      let betaCYear = null;
      
      let cutoffPoint = action.payload.input_parameters.inspectability;
      
      for (let key in betaC) {
        let betaCValue = betaC[key];
        let betaTValue = betaT[key];
        console.log(parseFloat(betaCValue), cutoffPoint, key);
        if (parseFloat(betaCValue) >= cutoffPoint && betaCYear === null) {
          betaCYear = parseInt(key);
        }

        if (parseFloat(betaTValue) >= cutoffPoint && betaTYear === null) {
          betaTYear = parseInt(key);
        }
        
        betaCResult.push({
          yProbability: (Math.round(betaCValue * 100 * 100) / 100).toFixed(2),
          xYear: key,
        });
        betaTResult.push({
          yProbability: (Math.round(betaTValue * 100 * 100) / 100).toFixed(2),
          xYear: key,
        });
      }
      if (!betaCYear && betaCYear !== 0) {
        betaCYear = 120;
      }
      if (!betaTYear && betaTYear !== 0) {
        betaTYear = 120;
      }

      results.push(betaTResult);
      results.push(betaCResult);

      return {
        ...state,
        userDataST: {
          ...state.userDataST,
          results,
          betaCYear,
          betaTYear,
          cutoffPoint
        }
      }
    }

    case constants.FETCHED_SETTINGS: {
      let feSettings = {};

      action.payload.forEach((country, idxCountry) => {
        let settingsObj = {};
        country.children.forEach((individualSetting, idxPageName) => {
          if (individualSetting.children.length > 0) {
            individualSetting.childrenObj = {};
            individualSetting.children.forEach((child, idx) => {
              individualSetting.childrenObj[child.name] = child;
              child.children.forEach((subChild, idx) => {
                individualSetting.childrenObj[child.name][subChild.name] = subChild;
              })
            })
          }
          if (settingsObj[individualSetting.type]) {
            settingsObj[individualSetting.type] = [...settingsObj[individualSetting.type], individualSetting]
          } else {
            settingsObj[individualSetting.type] = [individualSetting]
          }
        })
        feSettings[country.name] = settingsObj;
      })

      let LOCobjects = {};
      action.payload.forEach((country, idxCountry) => {
        if (idxCountry === 0) {
          LOCobjects[country.type] = {};
        }
        let CONobjects = {};
        country.children.forEach((individualSetting, idxPageName) => {
          if (individualSetting.type === 'CCT') {


            let CCVobjects = {};
            if (!CONobjects[individualSetting.type]) {
              CONobjects[individualSetting.type] = {};
            }

            individualSetting.children.forEach((subIndividualSetting, idxPageName) => {
              let CCW = {};

              if (idxPageName === 0) {
                CCVobjects[subIndividualSetting.type] = {};
              }

              subIndividualSetting.children.forEach((childSubIndividualSetting, idxPageName) => {
                let EXLobjects = {};
                if (idxPageName === 0) {
                  CCW[childSubIndividualSetting.type] = {};
                }
                CCW[childSubIndividualSetting.type][childSubIndividualSetting.name] = {
                  value: childSubIndividualSetting,
                  options: EXLobjects
                };
              });

              CCVobjects[subIndividualSetting.type][subIndividualSetting.name] =
              {
                value: subIndividualSetting,
                options: CCW
              };
            });
            CONobjects[individualSetting.type][individualSetting.name] =
            {
              value: individualSetting,
              options: CCVobjects
            };
          } else {
            let COMobjects = {};
            if (idxPageName === 0) {
              CONobjects[individualSetting.type] = {};
            }
            individualSetting.children.forEach((subIndividualSetting, idxPageName) => {
              let EXCobjects = {};

              if (idxPageName === 0) {
                COMobjects[subIndividualSetting.type] = {};
              }

              subIndividualSetting.children.forEach((childSubIndividualSetting, idxPageName) => {
                let EXLobjects = {};
                if (idxPageName === 0) {
                  EXCobjects[childSubIndividualSetting.type] = {};
                }

                childSubIndividualSetting.children.forEach((child1SubIndividualSetting, idxPageName) => {
                  if (idxPageName === 0) {
                    EXLobjects[child1SubIndividualSetting.type] = {};
                  }
                  //TODO setup value object
                  EXLobjects[child1SubIndividualSetting.type][child1SubIndividualSetting.name] = child1SubIndividualSetting;
                });
                EXCobjects[childSubIndividualSetting.type][childSubIndividualSetting.name] = {
                  value: childSubIndividualSetting,
                  options: EXLobjects
                };
              });

              COMobjects[subIndividualSetting.type][subIndividualSetting.name] =
              {
                value: subIndividualSetting,
                options: EXCobjects
              };

            });
            CONobjects[individualSetting.type][individualSetting.name] =
            {
              value: individualSetting,
              options: COMobjects
            };
          }
        })
        LOCobjects[country.type][country.name] =
        {
          value: country,
          options: CONobjects
        };
      });

      return {
        ...state,
        currentStep: 1,
        settings: {
          arr: action.payload,
          obj: feSettings,
          options: LOCobjects
        }
      }
    }

    case constants.FETCHED_VARIABLES: {
      //addToLSObject("userDataST", [constants.USERDATA_BAUWERK_DATA], action.payload);
      return {
        ...state,
        userDataST: {
          ...state.userDataST,
          EXPERT_MODE_CALCULATE_PARAMETER: {
            ...state.userDataST.EXPERT_MODE_CALCULATE_PARAMETER,
            ...action.payload
          }
        }
      }
      //return state;
    }
    
    // case '@@router/LOCATION_CHANGE': {
    //     console.log('location change: ',
    //         action.payload
    //     );

    //     return {
    //         ...state,
    //     }
    // }
  }

  return state;
};

export default app;
