import { DEFAULT_LOCALE } from "../constants";

const languagesMap = { 
  "en": ["en", "en-US", "en-EG", "en-AU", "en-GB", "en-CA", "en-NZ", "en-IE", "en-ZA", "en-JM", "en-BZ", "en-TT"],
  "fr": ["fr", "fr-BE", "fr-CA", "fr-CH", "fr-LU"],
  "de": ["de", "de-CH", "de-AT", "de-LU", "de-LI"],
  "it": ["it", "it-CH"]
};

export function detectBrowserLocale(): string {
  let nav = navigator as any;
  const lang = nav.language || nav?.userLanguage;
  let locale = DEFAULT_LOCALE;
  Object.keys(languagesMap).forEach(key => {
    if (languagesMap[key].indexOf(lang) !== -1) {
      locale = key;
    }
  });
  return locale;
}