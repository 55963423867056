import { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions';
import FormattedMessageCustom from '../../components/FormattedMessageCustom';
import { defineMessages } from 'react-intl';
import Select from 'react-select';
import { Button, EButtonType } from '../../components/Button';
import { ReactComponent as IconArrowDown } from '../../assets/icons/arrow-down.svg';

const translatableStrings = defineMessages({
  previousCalculations: {
    id: 'visualisation.previousCalculations',
    defaultMessage: 'Previous Calculations'
  },
  showChart: {
    id: 'visualisation.showChart',
    defaultMessage: 'Show chart'
  },
  previousCalculationsChoose: {
    id: 'visualisation.previousCalculationsChoose',
    defaultMessage: ''
  }
})

interface Props {
  userDataSTArchive: any;
  changeChart: any;
  intl: any;
  onSelect: Function;
}

function PreviousCalculations({ userDataSTArchive, intl, changeChart, onSelect }: Props) {
  const [nextChart, setNextChart] = useState<string>('')
  
  return userDataSTArchive.length > 1 ? <div key={userDataSTArchive.length}>
    <FormattedMessageCustom id={translatableStrings.previousCalculations.id}
      text={intl.formatMessage(translatableStrings.previousCalculations)}>
      <div className="title21 font-500 mb-5"></div>
    </FormattedMessageCustom>
    <div className="card">
      <Select
        components={{ 'DropdownIndicator': () => <IconArrowDown /> }}
        isSearchable={false}
        styles={{
          valueContainer: (provided, state) => ({
            ...provided,
            padding: 15,
          }),
          control: (base) => ({
            ...base,
            boxShadow: "none",
            border: "none !important"
          }),
        }}
        placeholder={intl.formatMessage(translatableStrings.previousCalculationsChoose)}
        className="dropdownInput"
        onChange={(e) => {
          setNextChart(e.value)
        }}
        options={
          userDataSTArchive.map((userData, idx) => {
            return {
              'label': idx + 1,
              'value': idx
            }
          })
        }
      />
      <hr />
      <Button
        className="mt-5"
        buttonType={EButtonType.InvertedPill}
        onClick={(e) => {
          changeChart(nextChart);
          const data = userDataSTArchive[nextChart]
          onSelect(data)
        }}
      >
        <FormattedMessageCustom id={translatableStrings.showChart.id}
          text={intl.formatMessage(translatableStrings.showChart)}>
          <span className=""></span>
        </FormattedMessageCustom>
      </Button>
    </div>
  </div> : null;
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    ...state.app,
  }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    changeChart: (data) => dispatch(actions.changeChart(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviousCalculations);
