import { Cookies } from 'react-cookie';
import { REST_API_URL } from '../constants';

// const apiUrl = process.env.REACT_APP_REST_API_URL;
const apiUrl = REST_API_URL;
const cookies = new Cookies();

function getBrowserLocale(options = {}) {
  const defaultOptions = { countryCodeOnly: true }

  const opt = { ...defaultOptions, ...options }

  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language

  if (!navigatorLocale) {
    return undefined
  }

  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim();
  return trimmedLocale
};

export default function apiCaller<T>(method: string, path: string, data?: any, headers?: object): Promise<T[] | null> {
  const apiToken = cookies.get('bearerToken');

  return fetch(apiUrl + path, {
    method,
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": (window as any).localStorage.getItem("locale") ? (window as any).localStorage.getItem("locale") : getBrowserLocale(),
      "Authorization": `Bearer ${apiToken}`,
      ...headers
    },
    body: data ? JSON.stringify(data) : null
  }).then(res => {
    if (res.ok) {
      return res.json();
    } else {
      return res.json().then((err) => {
        throw err;
      })
    }
  });
}
