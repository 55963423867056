import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import classNames from 'classnames';
import { RootState } from '../../../reducers';
import * as actions from '../../../actions';
import { defineMessages } from 'react-intl';
import { ReactComponent as IconCheckmark } from '../../../assets/icons/checkmark.svg';
import FormattedMessageCustom from '../../FormattedMessageCustom';

const translatableStrings = defineMessages({
    projektstandort: {
        id: 'navigation.projektstandort',
        defaultMessage: 'Projektstandort'
    },
})

interface State {
}

interface Props {
    userDataST: any;
    intl: any;
    completed: boolean;
    active: boolean;
}


class First extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        this.setState({
            readyToAnimate: true
        });

    }

    componentWillUnmount() {
    }


    render() {
        return (
            <div className={classNames('pill', {
                'completed': this.props.completed,
                'active': this.props.active,
            })}
            >
                <div className="stepPill">
                    {this.props.completed ? <IconCheckmark /> : '1'}
                </div>
                <FormattedMessageCustom id={translatableStrings.projektstandort.id} text={this.props.intl.formatMessage(translatableStrings.projektstandort)}>
                    <div
                        className={classNames('stepTitle', {
                        })}
                    >
                    </div>
                </FormattedMessageCustom>
            </div>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(First);
