import { StyleSheet } from '@react-pdf/renderer';

export function pxToPts(px) {
  //	A4 dime 8.3 x 11.7 in
  // figma height 1684
  return (px / 1684) * 11.7 * 72;
}

// Create styles
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    padding: pxToPts(65),
  },
  header: {
    height: pxToPts(210),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: pxToPts(21),
    borderBottom: '1px solid #F4F4F4',
    marginTop: -pxToPts(65)  
  },
  headerText: {
    marginTop: pxToPts(30)
  },
  logo: {
    height: pxToPts(67),
    width: pxToPts(120)
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: pxToPts(120)
  },
  settingsSection: {
    padding: `0 ${pxToPts(56)}`,
    marginLeft: -pxToPts(56),
    width: pxToPts(300),
  },
  settingsTitle: {
    fontSize: pxToPts(21),
    borderBottom: '1px solid #F4F4F4',
    padding: `0 0 ${pxToPts(21)}, 0`
  },
  settingsSubSection: {
    marginTop: pxToPts(32),
    fontSize: pxToPts(14),
    color: '#757575'
  },
  settingsSubTitle: {
    fontSize: pxToPts(16),
  },
  mainSection: {
    display: 'flex',
    flexDirection: 'column',
    borderLeft: '1px solid #F4F4F4'
  },
  chart: {
    width: '100%'
  },
  chartImage: {
    width: '100%',
    marginTop: -pxToPts(30)
  },
  pill: {
    width: pxToPts(350),
    backgroundColor: 'rgba(102, 102, 102, 0.1)',
    borderRadius: pxToPts(24),
    padding: pxToPts(24),
    fontSize: pxToPts(16),
    minHeight: '30px'
  },
  chartSection: {
    position: 'relative',
    width: pxToPts(820),
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  findOutMoreSectionExpert: {
    width: pxToPts(820),
    backgroundColor: '#FAFAFA',
    marginTop: pxToPts(30),
    marginLeft: pxToPts(30),
    marginRight: pxToPts(30),
    padding: pxToPts(32),
    borderRadius: pxToPts(24),
    height: pxToPts(156),
    borderBottom: '1px solid #F4F4F4',
  },
  findOutMoreSectionDisclaimerExpert: {
    width: pxToPts(820),
    backgroundColor: '#FAFAFA',
    marginTop: pxToPts(160),
    marginLeft: pxToPts(30),
    marginRight: pxToPts(30),
    padding: pxToPts(32),
    borderRadius: pxToPts(24),
    borderBottom: '1px solid #F4F4F4',
    height: pxToPts(226),
  },
  findOutMoreSectionDisclaimer: {
    width: '100%',
    backgroundColor: '#FAFAFA',
    position: 'absolute',
    left: pxToPts(65),
    bottom: pxToPts(250),
    padding: pxToPts(32),
    borderRadius: pxToPts(24),
    borderBottom: '1px solid #F4F4F4',
    height: pxToPts(180),
  },
  findOutMoreSection: {
    width: '100%',
    backgroundColor: '#FAFAFA',
    position: 'absolute',
    left: pxToPts(65),
    bottom: pxToPts(65),
    padding: pxToPts(32),
    borderRadius: pxToPts(24),
    height: pxToPts(156),
    borderBottom: '1px solid #F4F4F4',
  },
  findOutMore: {
    fontSize: pxToPts(21),
    fontFamily: 'Helvetica',
    marginBottom: pxToPts(12),
  },
  findOutMore2: {
    fontSize: pxToPts(15),
    color: '#757575'
  }
});

export default styles;