import FormattedMessageCustom from '../FormattedMessageCustom';
import { defineMessages } from 'react-intl';
import { Button } from '../Button';
import { RootState } from '../../reducers';
import { connect } from 'react-redux';

interface KnowMoreProps {
  intl: any;
  title?: any;
  text?: any;
  btn?: any;
}

const translatableStrings = defineMessages({
  title: {
    id: 'knowMoreCard.title',
    defaultMessage: ' '
  },
  description: {
    id: 'knowMoreCard.description',
    defaultMessage: ' '
  },
  contactUs: {
    id: 'knowMoreCard.contactUs',
    defaultMessage: ' '
  },
})

function KnowMoreCardComponent(props: KnowMoreProps) {
  const title = props.title || translatableStrings.title
  const text = props.text || translatableStrings.description
  const btn = props.btn || translatableStrings.contactUs
  return (<div className="subCards cards d-flex">
    <div className="card contact nonShadow col-12 small bgGray align-items-start flex-row flex-wrap">
      <FormattedMessageCustom id={title.id}
        text={props.intl.formatMessage(title)}>
        <p className="title21 font-500 w-100"></p>
      </FormattedMessageCustom>
      <FormattedMessageCustom id={text.id}
        text={props.intl.formatMessage(text)}>
        <p className="font-16 font-300 text-left col-8 px-0 colorGrayDark white-space-pre-wrap"></p>
      </FormattedMessageCustom>
      <Button
        className="col-4 invertedPill contactUsButton"
        to="?modal=contact"
      >
        <FormattedMessageCustom id={btn.id}
          text={props.intl.formatMessage(btn)}>
          <span className=""></span>
        </FormattedMessageCustom>
      </Button>
    </div>
  </div>)
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    ...state.app,
  }
}

const KnowMoreCard = connect(mapStateToProps, null)(KnowMoreCardComponent);
export default KnowMoreCard;