import React from 'react'
import { defineMessages } from 'react-intl';
import * as actions from '../../actions';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import { bindActionCreators, Dispatch } from 'redux';
import "./Contact.scss";
import { Button, EButtonType } from '../Button';
import { ReactComponent as IconX } from '../../assets/icons/x.svg';
import FormattedMessageCustom from '../FormattedMessageCustom';
import { toastr } from 'react-redux-toastr';
import classNames from 'classnames';
import {
  // GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';

interface Props {
  history: any;
  intl: any;
  onClose?: any;
  errors?: any;
  actions: any;
  userDataEC?: any;
}

interface State {
  firstname: string;
  lastname: string;
  email: string;
  company: string;
  recaptchaCode: string;
  message: string;
}

type FormState<State> = { [key in keyof State]: State[key] };

const translatableStrings = defineMessages({
  title: {
    id: 'contact.navigation',
    defaultMessage: 'Contact Us'
  },
  firstname: {
    id: 'contact.firstname',
    defaultMessage: 'First name'
  },
  lastname: {
    id: 'contact.lastname',
    defaultMessage: 'Last name'
  },
  email: {
    id: 'contact.email',
    defaultMessage: 'Email'
  },
  company: {
    id: 'contact.company',
    defaultMessage: 'Company'
  },
  message: {
    id: 'contact.message',
    defaultMessage: 'Your message'
  },
  submit: {
    id: 'contact.submit',
    defaultMessage: 'Submit'
  },
})


class Contact extends React.Component<Props, State> {
  mounted: boolean;
  constructor(props: Props) {
    super(props)
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      company: "",
      message: "",
      recaptchaCode: null,
    }
  }

  submitMessage = () => {
    if (!this.state.recaptchaCode) return;
    
    const payload = {
      "first_name": this.state.firstname,
      "last_name": this.state.lastname,
      "email": this.state.email,
      "company": this.state.company,
      "message": this.state.message,
      "recaptcha": this.state.recaptchaCode
    }
    this.props.actions.postMessage(payload);
  }

  handleVerify = (e) => {
    console.log('handleVerify', e);
    this.setState({ recaptchaCode: e })
  }

  componentDidMount(): void {
    this.mounted = true
  }

  render() {
    console.log(this.state)
    return (<>
      <GoogleReCaptcha onVerify={this.handleVerify} key={this.mounted ? 1 : 0} />
      <div className="modalContainer" id="root">
        <div className="closeIcon">
          <Button
            onClick={() => {
              this.props.onClose ? this.props.onClose() : this.props.history.push({
                search: ''
              })
            }}
          >
            <IconX />
          </Button>
        </div>
        <FormattedMessageCustom id={translatableStrings.title.id}
          text={this.props.intl.formatMessage(translatableStrings.title)}>
          <p className="font-500 font-24">
          </p>
        </FormattedMessageCustom>
        <hr />
        <form className="text-center" onSubmit={(e) => {
          e.preventDefault();
          this.submitMessage();
          toastr.success('Contact submitted', 'Thank you, will be in touch shortly.', { timeOut: 3000 });
          this.props.onClose ? this.props.onClose() : this.props.history.push({
            search: ''
          });
        }}>
          <div className="d-flex flex-wrap colContainer">
            {
              Object.keys(this.state).map((key, idx) => {
                return key !== "recaptchaCode" && (
                  <div className={classNames('col-6 px-0 mb-4 inputCol', {
                    'col-12': key == 'message'
                  })} key={idx}>
                    <FormattedMessageCustom id={translatableStrings[key].id}
                      text={this.props.intl.formatMessage(translatableStrings[key])}>
                      <label htmlFor={key} className="font-16 font-500">
                      </label>
                    </FormattedMessageCustom>

                    {key == 'message' ? <textarea
                      id={key}
                      placeholder={'Enter text here...'}
                      name={key} 
                      onChange={(e) => {
                        this.setState({
                          [key]: e.target.value
                        } as FormState<State>)
                      }} /> :
                      <input
                        id={key}
                        name={key}
                        type='text'
                        required
                        className="customInput"
                        onChange={(e) => {
                          this.setState({
                            [key]: e.target.value
                          } as FormState<State>)
                        }}
                        value={this.state[key]}
                      />}
                  </div>
                )
              })
            }
          </div>
          <Button
            className="mt-auto"
            buttonType={EButtonType.InvertedPill}
            submit
          >
            <FormattedMessageCustom id={translatableStrings.submit.id}
              text={this.props.intl.formatMessage(translatableStrings.submit)}>
              <span className=""></span>
            </FormattedMessageCustom>
          </Button>
        </form>

        <div className="row">
          <div className="col-4">
          </div>
        </div>
      </div>
    </>)
  }
}


function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    ...state.app,
  }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    actions: bindActionCreators(actions as any, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
