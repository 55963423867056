import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions';
import './PDFReportPage.scss';
import { MyDoc } from '../../components/PdfCreator/PdfCreator';
import { PDFViewer } from '@react-pdf/renderer';


interface State {
}

interface Props {
  actions: any;
  history: any;
  userDataST: any;
  intl: any;
}

class PDFReportPage extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    document.body.classList.add('pdfReportPagePage');
  }

  componentWillUnmount() {
    document.body.classList.remove('pdfReportPagePage');
  }

  render() {
    return (
      <div className="pdfPageContainer pageContainer">
        <PDFViewer>
          <MyDoc intl={this.props.intl} userData={this.props.userDataST} />
        </PDFViewer>
      </div>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    ...state.app,
  }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PDFReportPage);
