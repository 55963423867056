import { useEffect, useRef, useState } from "react";
import { select, svg, scale, interpolateArray } from "d3";
import "./index.scss";
import styles from "./Chart.module.scss";
import classNames from "classnames";

interface Props {
  name: string;
  chartData: Array<Array<{
    xYear: number;
    yProbability: number;
  }>>;
  userSelectionData: any;
  chartWidth?: number;
  fixedChartHeight?: number;
  disableResize?: any;
  labels: { x: string; y: string; }
  showCutoff?: boolean;
}

export default function Chart({
  name,
  chartData,
  chartWidth,
  disableResize,
  fixedChartHeight,
  labels,
  userSelectionData,
  showCutoff = false
}: Props) {
  const initialHeight = Math.max(window.innerHeight - 700, 420);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(fixedChartHeight || initialHeight);
  const [data, setData] = useState<{ xYear: number; yProbability: number }[][]>([
    [{ xYear: 0, yProbability: 0 }], [{ xYear: 0, yProbability: 0 }]
  ]);
  const [tooltip, setTooltip] = useState({
    xYear: "",
    yProbability: "",
    top: null,
    left: null,
  });

  const ref: any = { [`${name}`]: useRef() };
  const xStepSize: number = 20;

  var DURATION = 0;

  useEffect(() => {
    if (chartWidth) {
      setWrapperWidth(chartWidth);
    }
  }, [chartWidth]);

  useEffect(() => {
    if (chartData && chartData.length > 0) {
      setData(chartData);
    }
  }, [chartData]);

  useEffect(() => {
    const handleResize = () => {
      if ((ref[name].current.offsetWidth !== wrapperWidth &&
        !disableResize &&
        !chartWidth) ||
        (!chartHeight && chartHeight !== initialHeight)
      ) {
        setWrapperWidth(ref[name].current.offsetWidth);
        setChartHeight(fixedChartHeight || initialHeight);
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ref[name].current) {
      setWrapperWidth(ref[name].current.offsetWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref[name].current, setWrapperWidth]);

  function drawLineChart(elementId, dataArr, w) {
    let data = dataArr[0];
    let data2 = dataArr[1];

    if (data != null && data.constructor.name === "Object") {
      return;
    }

    if (data2 != null && data.constructor.name === "Object") {
      return;
    }

    var containerEl = document.getElementById(elementId),
      width = w,
      height = chartHeight || 200,
      margin = {
        top: 50,
        right: 15,
        left: 0,
        bottom: 30,
      },
      detailWidth = 48,      
      container = select(containerEl),
      CSvg = container
        .select("svg")
        .attr("width", width)
        .attr("height", height + margin.top),

      x = scale.linear().range([0, width - detailWidth]),
      y = scale.linear().range([height, 0])

    var xAxis = svg
      .axis()
      .scale(x)
      .ticks(6)
      .tickSize(-height)
      .tickFormat(function (d) {
        return d;
      }),

      xAxisTicks = svg
        .axis()
        .scale(x)
        .ticks(6)
        .tickSize(height)
        .tickFormat("")
        .orient("bottom"),

      yAxis = svg
        .axis()
        .scale(y)
        .tickValues([
          0, 
          ((userSelectionData.cutoffPoint === 0.5 ? userSelectionData.cutoffPoint - 0.1 : userSelectionData.cutoffPoint) * 100).toFixed(0),
          1
        ])
        .orient("left")
        .tickFormat(function (d) {
          return d;
        }),

      area = svg
        .area()
        .interpolate("linear")
        .x(function (d) {
          return x(d.xYear) + 0 * detailWidth / 2;
        })
        .y0(height)
        .y1(function (d) {
          return y(d.yProbability);
        }),
      area2 = svg
        .area()
        .interpolate("linear")
        .x(function (d) {
          return x(d.xYear) + 0 * detailWidth / 2;
        })
        .y0(height)
        .y1(function (d) {
          return y(d.yProbability);
        }),
      line = svg
        .line()
        .interpolate("basis")
        .x(function (d) {
          return x(d.xYear) + 0 * detailWidth / 2;
        })
        .y(function (d) {
          return y(d.yProbability);
        }),
      line2 = svg
        .line()
        .interpolate("basis")
        .x(function (d) {
          return x(d.xYear) + 0 * detailWidth / 2;
        })
        .y(function (d) {
          return y(d.yProbability);
        }),
      startData = data.map(function (datum) {
        return {
          xYear: datum.xYear,
          yProbability: 0,
        };
      }),
      startData2 = data2.map(function (datum) {
        return {
          xYear: datum.xYear,
          yProbability: 0,
        };
      }),
      circleContainer; // Compute the minimum and maximum xYear, and the maximum yProbability.

    x.domain([data[0].xYear, data[data.length - 1].xYear]);
    y.domain([0, 40]);

    CSvg.append("g")
      .attr("class", "lineChart--xAxis")
      .attr(
        "transform",
        "translate(" + (detailWidth / 2 - 45) + "," + (height + 5) + ")"
      )
      .call(xAxis);


    CSvg.append("g")
      .attr("class", "lineChart--xAxis lineChart--yAxis")
      .attr("transform", `translate(${-40},${0})`)
      .call(yAxis);


    CSvg.append("g")
      .attr("class", "lineChart--xAxisTicks")
      .attr("transform", "translate(" + 3 + ",0)")
      .call(xAxisTicks); // Add the line path.

    // X axis label
    CSvg.select(".lineChart--xAxis")
      .append("text")
      .attr("class", "xLabel")
      .attr("text-anchor", "end")
      .attr("x", width / 2 + 120)
      .attr("y", 60)
      .text(labels.x);

    // Y axis label
    CSvg.select(".lineChart--yAxis")
      .append("text")
      .attr("class", "yLabel")
      .attr("text-anchor", "end")
      .attr("transform", "rotate(-90)")
      .attr("x", -height / 2)
      .attr("y", -15)
      .text(labels.y);

    // Background between ticks
    CSvg.selectAll(".lineChart--xAxisTicks .tick").each(function (this: any, val, idx) {
      const tick = select(this);

      tick.insert('rect', ':first-child')
        .attr('x', 0)
        .attr('y', 0)
        .attr('height', height + 6)
        .attr('width', x(xStepSize))
    })

    CSvg.select(`.${name}char-container`)
      .append("path")
      .datum(startData)
      .attr("class", "lineChart--areaLine")
      .attr("d", line)
      .transition()
      .duration(0)
      .delay(0)
      .attrTween("d", tween(data, line))
      .each("end", function () {
        drawCircles(data);
      }); // Add the area path.

    CSvg.select(`.${name}char-container2`)
      .append("path")
      .datum(startData2)
      .attr("class", "lineChart--areaLine lineChart--areaLine2")
      .attr("d", line2)
      .transition()
      .duration(0)
      .delay(0)
      .attrTween("d", tween(data2, line2))
      .each("end", function () {
        drawCircles(data2);
      }); // Add the area path.

    CSvg.selectAll(".tick text")
      .attr("style", "font-size:14px; fill:#000000;font-weight: 500;")
      .attr("x", "1.32em")
      .attr("dy", "1.32em");

    CSvg.select(`.${name}char-container`)
      .append("path")
      .datum(startData)
      .attr("class", "lineChart--area")
      .attr("d", area)
      .transition()
      .duration(0)
      .attrTween("d", tween(data, area)); // Helper functions!!!

    CSvg.select(`.${name}char-container2`)
      .append("path")
      .datum(startData2)
      .attr("class", "lineChart--area lineChart--area2")
      .attr("d", area2)
      .transition()
      .duration(0)
      .attrTween("d", tween(data2, area2)); // Helper functions!!!

    if (userSelectionData.betaCYear > 0 && userSelectionData.betaCYear < 120) {
      CSvg.select(`.${name}char-container2`).append("line")
        .attr("x1", x(userSelectionData.betaCYear))
        .attr("x2", x(userSelectionData.betaCYear))
        .attr("y1", height + 6)
        .attr("class", "lineChart--area")
        .attr("y2", y(userSelectionData.cutoffPoint * 100))
        .attr("stroke", '#41B6E6')
        .style("stroke-width", 0.5)
        .style('shape-rendering', 'crispEdges')
    }

    if (userSelectionData.betaTYear > 0 && userSelectionData.betaTYear < 120) {
      CSvg.select(`.${name}char-container2`).append("line")
        .attr("class", "lineChart--area")
        .attr("x1", x(userSelectionData.betaTYear))
        .attr("x2", x(userSelectionData.betaTYear))
        .attr("y1", height + 6)
        .attr("y2", y(userSelectionData.cutoffPoint * 100))
        .attr("stroke", '#41B6E6')
        .style("stroke-width", 0.5)
        .style('shape-rendering', 'crispEdges')
    }

    if (userSelectionData.cutoffPoint > 0 && showCutoff) {
      CSvg.select(`.${name}char-container2`).append("svg:line")
        .attr("x1", 0)
        .attr("y1", y((userSelectionData.cutoffPoint * 100).toFixed(0)))
        .attr("x2", width - 48)
        .attr("y2", y((userSelectionData.cutoffPoint * 100).toFixed(0)))
        .attr("class", "lineChart--area")
        .style("stroke-width", 0.5)
        .style('shape-rendering', 'crispEdges')
        .style("stroke", "#41B6E6")
        .style("stroke-dasharray", ("5, 5"))
        .transition()
        .duration(0)
        .delay(0)        
    }

    function drawCircle(datum, index) {
      circleContainer
        .datum(datum)
        .append("circle")
        .attr("class", "lineChart--circle")
        .attr("style", "opacity:0;")
        .attr("r", 0)
        .attr("cx", function (d) {
          return x(d.xYear) + 5;
        })
        .attr("cy", function (d) {
          return y(d.yProbability);
        })
        .on("mouseenter", function (this, d) {
          select(this)
            .attr(
              "class",
              "lineChart--circle lineChart--circle__highlighted"
            )
            .attr("style", "opacity:1;")
            .attr("r", 7);

          // select(`.${name}indicator-line`)
          //     .attr("y2", `${height}px`)
          //     .attr("y1", () => y(d.yProbability))
          //     .attr("x1", () => x(d.xYear) + detailWidth / 2)
          //     .attr("x2", () => x(d.xYear) + detailWidth / 2)
          //     .attr("stroke-width", "1px")
          //     .attr("stroke", "#242F38");

          d.active = true;
          showCircleDetail(d);
        })
        .on("mouseout", function (this, d) {
          select(this)
            .attr("class", "lineChart--circle")
            .attr("r", 6)
            .attr("style", "opacity:0;");

          // select(`.${name}indicator-line`).attr(
          //     "stroke-width",
          //     "0px"
          // );

          if (d.active) {
            hideCircleDetails();
            d.active = false;
          }
        })
        .on("click touch", function (d) {
          if (d.active) {
            showCircleDetail(d);
          } else {
            hideCircleDetails();
          }
        })
        .transition()
        .delay((DURATION / 10) * index)
        .attr("r", 6);
    }

    function drawCircles(data) {
      circleContainer = CSvg.append("g");
      data.forEach(function (datum, index) {
        drawCircle(datum, index);
      });
    }

    function hideCircleDetails() {
      setTooltip({
        xYear: "",
        yProbability: "",
        top: null,
        left: null,
      });
    }

    function showCircleDetail(data) {
      setTooltip({
        xYear: data.xYear,
        yProbability: data.yProbability,
        top: y(data.yProbability) - 44,
        left: x(data.xYear),
      });
    }

    function tween(b, callback) {
      return function (a) {
        var i = interpolateArray(a, b);
        return function (t) {
          return callback(i(t));
        };
      };
    }
  }

  useEffect(() => {
    drawLineChart(name + "lineChart", data, wrapperWidth);
    return () => {
      select(document.getElementById(name + "lineChart"))
        .selectAll("g")
        .remove();
      select(document.getElementById(name + "lineChart"))
        .selectAll("path")
        .remove();
      select(document.getElementById(name + "lineChart"))
        .selectAll("line")
        .remove();

    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperWidth, chartWidth, data]);

  return (
    <div className={classNames(styles.chartsContainer, 'w-100 chartsContainer')} ref={ref[name]} id={name}>
      <div
        className={styles.tooltip}
        style={{
          opacity: tooltip.top ? 1 : 0,
          top: tooltip.top,
          left: tooltip.left,
        }}
      >
        <div className={styles.tooltipAmount}>{tooltip.yProbability}</div>
      </div>
      <ul className={styles.ul}>
        <li className="chart">
          <div id={name + "lineChart"}>
            <svg id="lineChartSVG" className="lineChart--svg"
              viewBox={disableResize ? `-100 0 ${wrapperWidth + 100} 450` : ""}
            >
              <svg
                className={name + "char-container"}
                id={name + "lineChart--areaLine"}
              >
              </svg>
              <svg
                className={name + "char-container2"}
                id={name + "lineChart--areaLine2 "}
              >
              </svg>
            </svg>
          </div>
        </li>
      </ul>
    </div>
  );
}
