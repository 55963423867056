import { getParameterByName } from '../utils/toSearchParams';
import { defined } from '../utils/variableEvaluation';
import { updateIntl } from 'react-intl-redux';
import { detectBrowserLocale } from '../utils/language';

function detectAndUpdateLocale(dispatch, state) {
  let locale = getParameterByName('lang');

  if (!defined(locale)) {
    let selectedLanguage = (window as any).localStorage.getItem("locale");
    if (defined(selectedLanguage)) {
      locale = selectedLanguage;
    }
  }
  if (!defined(locale)) {
    locale = detectBrowserLocale();
  }
  if (state.intl.locale !== locale) {
    dispatch(updateIntl({ locale, messages: state.locales[locale] })); 
  }
}

export function sideActions({ dispatch, getState }) {
  let localeSet = false;
  return next => action => {
    const state = getState();
    if (action.type === "@@router/LOCATION_CHANGE") {
      if (action.payload.location.pathname === '/expert') {
        if (!localeSet) {
          dispatch(updateIntl({ locale: 'en', messages: state.locales["en"] }));
          localeSet = true;
        }
        return next(action);
      }
      
      if (!localeSet) detectAndUpdateLocale(dispatch, state);
      localeSet = true;
    }
    return next(action);
  };
}
