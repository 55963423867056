import ExpertModeField from "./ExpertMode.Field"
import translatableStrings from './translations';

type Props = { intl: any, onChangeValue: Function, expertModeParameters: any };

function SelectListExpert({ intl, onChangeValue, expertModeParameters }: Props) {
  const fields = [
    {
      msg: translatableStrings.concreteThicknessExpert,
      key: 'x_mu',
      tooltip: 'Mean value of concrete cover, normal distribution (ND)'
    },
    {
      msg: translatableStrings.concreteThicknessSigma,
      key: 'x_sigma',
      tooltip: 'Standard deviation of concrete cover, normal distribution (ND)'
    },
    {
      msg: translatableStrings.diffussionCoeff,
      key: 'd_mu',
      tooltip: 'Mean value of chloride migration coefficient, normal distribution (ND).'
    },
    {
      msg: translatableStrings.diffussionCoeffStdev,
      key: 'd_sigma',
      tooltip: 'Standard deviation of chloride migration coefficient, normal distribution (ND).'
    },
    {
      msg: translatableStrings.chlorideExpositionMean,
      key: 'cs_mu',
      tooltip: 'Mean value of the chloride concentration at a depth of x=10mm, logarithmic normal distribution (LogN)'
    },
    {
      msg: translatableStrings.chlorideExpositionStdev,
      key: 'cs_sigma',
      tooltip: 'Standard deviation of the chloride concentration at a depth of x=10mm, logarithmic normal distribution (LogN)'
    },
    {
      msg: translatableStrings.concreteCoverage,
      key: 'd_x',
      tooltip: `Depth of the convection zone (near-surface concrete layer up to which the process of chloride penetration differs from Fick's 2nd law of diffusion)`
    },
    {
      msg: translatableStrings.corrosionProbability,
      key: 'inspectability',
      tooltip: 'Permissible corrosion probability in the serviceability limit state (depassivation)',
      format: (v) => (v ? parseFloat(v) / 100 : 0),
      defaultValue: `${expertModeParameters.inspectability * 100}`
    },
    {
      msg: translatableStrings.ccrit_c_mu,
      key: 'ccrit_c_mu',
      tooltip: 'Mean value of the critical corrosion-inducing chloride content of steel A, logarithmic normal distribution (LogN), e.g. B500B: μ = 0,6 M.-%/z'
    },
    {
      msg: translatableStrings.ccrit_c_sigma,
      key: 'ccrit_c_sigma',
      tooltip: 'Standard deviation of the critical corrosion-inducing chloride content of steel A, logarithmic normal distribution (LogN), e.g. B500B: σ = 0,15 M.-%/z'
    },
    {
      msg: translatableStrings.ccrit_t_mu,
      key: 'ccrit_t_mu',
      tooltip: 'Mean value of the critical corrosion-inducing chloride content of steel B, logarithmic normal distribution (LogN), e.g. Top12: μ = 2,34 M.-%/z'
    },
    {
      msg: translatableStrings.ccrit_t_sigma,
      key: 'ccrit_t_sigma',
      tooltip: 'Standard deviation of the critical corrosion-inducing chloride content of steel B, logarithmic normal distribution (LogN), e.g. Top12: σ = 0,69 M.-%/z'
    },
    {
      msg: translatableStrings.n_mu,
      key: 'n_mu',
      tooltip: 'Mean value of the binder-dependent aging exponent n, logarithmic normal distribution (LogN)'
    },
    {
      msg: translatableStrings.n_sigma,
      key: 'n_sigma',
      tooltip: 'Standard deviation of the binder-dependent aging exponent n, logarithmic normal distribution (LogN)'
    },
  ];
  
  return <>
    {fields.map((field, i) => {
      const values = {
        ...field.defaultValue && { defaultValue: field.defaultValue },
        ...!field.defaultValue && { value: expertModeParameters[field.key] },
      }
      
      return <ExpertModeField inputType="number"
        messageId={field.msg.id}
        message={intl.messages[field.msg.id] || field.msg.defaultMessage}
        key={intl.messages[field.msg.id] || field.msg.id}
        onChange={(v) => {
          let value = field.format ? field.format(v) : v;
          onChangeValue(field.key, value);
        }}
        {...values}
        tooltipMsg={field.tooltip}
      />
    })}
  </>
}

export default SelectListExpert;