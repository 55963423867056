import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions';
import './Visualisation.scss';
import SelectList from '../../components/Selectlist/Selectlist';
import FormattedMessageCustom from '../../components/FormattedMessageCustom';
import { defineMessages } from 'react-intl';
import { animateOutAndNavigate } from '../../utils/react';
import Chart from '../../components/Chart/Chart';
import { Button } from '../../components/Button';
import { ReactComponent as IconCog } from '../../assets/icons/cog.svg';
import { ReportFileLink } from '../../components/PdfCreator/PdfCreator';
import LanguageSwitcher from "../../components/LanguageSwitcher";
import KnowMoreCard from '../../components/KnowMoreCard/KnowMoreCard';
import PreviousCalculations from '../../components/PreviousCalculations/PreviousCalculations';
import DownloadButton from '../../components/DownloadButton/DownloadButton';

const translatableStrings = defineMessages({
  title: {
    id: 'visualisation.title',
    defaultMessage: 'Your Calculation'
  },
  previousCalculations: {
    id: 'visualisation.previousCalculations',
    defaultMessage: 'Previous Calculations'
  },
  showChart: {
    id: 'visualisation.showChart',
    defaultMessage: 'Show chart'
  },
  top12Steel: {
    id: 'concreteType.top12Steel',
    defaultMessage: 'Top 12 Steel'
  },
  years: {
    id: 'concreteType.years',
    defaultMessage: 'Jahre'
  },
  blackSteel: {
    id: 'concreteType.blackSteel',
    defaultMessage: 'Black steel'
  },
  steelA: {
    id: 'concreteType.steelA',
    defaultMessage: 'Steel A'
  },
  steelB: {
    id: 'concreteType.steelB',
    defaultMessage: 'Steel B'
  },
  next: {
    id: 'navigation.next',
    defaultMessage: 'Weiter'
  },
  xLabel: {
    id: 'visualisation.chartXLabel',
    defaultMessage: ''
  },
  yLabel: {
    id: 'visualisation.chartYLabel',
    defaultMessage: ''
  },
  previousCalculationsChoose: {
    id: 'visualisation.previousCalculationsChoose',
    defaultMessage: ''
  }
})

interface State {
  visualisation: string;
  hovered: boolean;
  nextChart: string;
  chartImage: any;
  sidebarVisible: boolean;
  pdfData: any;
}

interface Props {
  actions: any;
  history: any;
  userDataST: any;
  userDataSTArchive: any;
  setUserDataVisualisation: any;
  createNewChart: any;
  intl: any;
  location: any;
}


class Visualisation extends React.Component<Props, State> {
  isExpertMode = false;

  constructor(props: any) {
    super(props);
    this.firstInput = React.createRef();
    this.state = {
      visualisation: this.props.userDataST.USERDATA_STANDORT,
      hovered: false,
      nextChart: null,
      chartImage: null,
      sidebarVisible: false,
      pdfData: {...this.props.userDataST}
    }
  }

  private firstInput = null;

  componentDidMount() {
    document.body.classList.add('visualisationPage');
    document.addEventListener('keyup', this.handlePresses);
    (window as any).name = Date.now();
    if (this.props.location.pathname === '/expert') {
      this.isExpertMode = true;
    }
    if (this.isExpertMode) {
      this.props.actions.fetchResults(this.props.userDataST.EXPERT_MODE_CALCULATE_PARAMETER)
        .then(() => {
          this.props.actions.initChartArchive()
        });
    } else {
      this.props.actions.fetchResults(this.props.userDataST.CALCULATE_PARAMETER)
        .then(() => {
          this.props.actions.initChartArchive()
        });
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('visualisationPage');
    document.removeEventListener('keyup', this.handlePresses);
  }

  componentDidUpdate(): void {
    if ((this.state.pdfData.betaCYear !== this.props.userDataST.betaCYear)
      || (this.state.pdfData.betaTYear !== this.props.userDataST.betaTYear)) {
      const pdfData = {
        ...this.state.pdfData,
        betaCYear: this.props.userDataST.betaCYear,
        betaTYear: this.props.userDataST.betaTYear
      }
      this.setState({ pdfData })
    }
  }

  handlePresses = (e) => {
    if (e.code === "ArrowRight") {
      this.setState({
        visualisation: 'ch'
      }, () => {
        this.firstInput.current.focus();
      })

      document.removeEventListener('keyup', this.handlePresses);
    } else if (e.code === "Tab") {
      document.removeEventListener('keyup', this.handlePresses);
      this.setState({
        visualisation: 'ch'
      });
    }
  }

  choose = (e) => {
    this.props.setUserDataVisualisation(this.state.visualisation);

    animateOutAndNavigate(() => {
      this.props.history.push({
        pathname: `/bauwerk`
      });
    })
  }

  createChart = (data, isSilent = false) => {
    this.props.createNewChart({ params: data, isSilent })
  }

  render() {
    const isMobile = window.innerWidth < 900
    
    return (
      <div className="visualisationContainer pageContainer">
        <SelectList
          visible={this.state.sidebarVisible}
          history={this.props.history}
          intl={this.props.intl}
          results
          expert={this.isExpertMode}
          createNewChart={this.createChart}
          onUpdateParams={(pdfData) => {
            this.setState({ pdfData })
          }}
        />
        <div className={`main justify-content-evenly stagger ${this.isExpertMode ? 'main_expert' : ''}`}>
          <div className="card mainCard col-xl-8 mx-0 mx-sm-4 col-12 pt-0 d-flex flex-row flex-wrap justify-content-start mx-0">
            
            <div className={`w-100 d-flex align-items-center headerWrap`}>
              <FormattedMessageCustom id={translatableStrings.title.id}
                text={this.props.intl.formatMessage(translatableStrings.title)}>
                <div className="header36 text-center d-block d-sm-none"></div>
              </FormattedMessageCustom>
              <Button
                className="d-block d-sm-none settingsButton"
                onClick={() => {
                  document.querySelector('#root').classList.add('mobileSidebar');
                  setTimeout(() => {
                    document.querySelector('#root').classList.remove('mobileHide');
                  }, 300)
                }}
              >
                <IconCog />
              </Button>
            </div>

            <Chart chartData={this.props.userDataST.results}
              fixedChartHeight={!isMobile ? 420 : 250}
              userSelectionData={this.props.userDataST}
              name="bottom-middle"
              showCutoff={true}
              labels={{
                x: this.isExpertMode ? 'Service life (years)' : this.props.intl.formatMessage(translatableStrings.xLabel),
                y: this.isExpertMode ? 'Probability of corrosion [%]' : this.props.intl.formatMessage(translatableStrings.yLabel)
              }}
              key={this.props.userDataST.key ? `${this.props.userDataST.key}-1` : "default-chart-1"}
            />

            <div className="d-flex justify-content-between pillsContainer">
              <div className="pill bgGreyLight d-flex flex-grow-1 align-items-center">
                <FormattedMessageCustom id={this.isExpertMode ? translatableStrings.steelA.id : translatableStrings.blackSteel.id}
                  text={this.props.intl.formatMessage(this.isExpertMode ? translatableStrings.steelA : translatableStrings.blackSteel)}>
                  <span className=""></span>
                </FormattedMessageCustom>
                <span className="colorGrey ml-auto mr-1 whiteSpace text-nowrap">
                  {(() => {
                    if (this.props.userDataST.betaCYear >= 120) {
                      return `> ${this.props.userDataST.betaCYear}`;
                    }
                    if (this.props.userDataST.betaCYear === 0) {
                      return `< 1`;
                    }
                    return this.props.userDataST.betaCYear;
                  })()}
                </span>
                <FormattedMessageCustom id={translatableStrings.years.id}
                  text={this.props.intl.formatMessage(translatableStrings.years)}>
                  <span className="colorGrey"></span>
                </FormattedMessageCustom>
              </div>
              <div className="pill bgRedLight d-flex flex-grow-1 align-items-center">
                <FormattedMessageCustom id={this.isExpertMode ? translatableStrings.steelB.id : translatableStrings.top12Steel.id}
                  text={this.props.intl.formatMessage(this.isExpertMode ? translatableStrings.steelB : translatableStrings.top12Steel)}>
                  <span className=""></span>
                </FormattedMessageCustom>
                <span className="colorRed ml-auto mr-1">
                 {(() => {
                    if (this.props.userDataST.betaTYear >= 120) {
                      return `> ${this.props.userDataST.betaTYear}`;
                    }
                    if (this.props.userDataST.betaTYear === 0) {
                      return `< 1`;
                    }
                    return this.props.userDataST.betaTYear;
                  })()}
                </span>
                <FormattedMessageCustom id={translatableStrings.years.id}
                  text={this.props.intl.formatMessage(translatableStrings.years)}>
                  <span className="colorRed"></span>
                </FormattedMessageCustom>
              </div>
              <div data-html2canvas-ignore className="pdfButton">
                {this.state.chartImage ?
                  <ReportFileLink userData={this.props.userDataST} /> : 
                  <DownloadButton 
                    isExpertMode={this.isExpertMode} 
                    pdfData={this.state.pdfData} 
                    intl={this.props.intl} 
                  />
                }
              </div>
            </div>

            <KnowMoreCard intl={this.props.intl} />
          </div>
          <div className="col-xl-3 col-12 col-sm-6 mx-0 my-4">
            <PreviousCalculations intl={this.props.intl} 
              userDataSTArchive={this.props.userDataSTArchive}
              onSelect={(data) => {
                this.setState({ pdfData: data })
              }}
            />
          </div>
        </div>

        <LanguageSwitcher isMobileMenu={true} intl={this.props.intl} />

        <div className="pdfChartWrapper">
          {/* Chart that is printed in PDF file, not visible in UI */}
          <Chart
            fixedChartHeight={!isMobile ? 420 : 250}
            chartData={this.props.userDataST.results}
            userSelectionData={this.props.userDataST}
            showCutoff={true}
            name="bottom-middle222"
            disableResize
            labels={{
              x: this.isExpertMode ? 'Service life (years)' : this.props.intl.formatMessage(translatableStrings.xLabel),
              y: this.isExpertMode ? 'Probability of corrosion [%]' : this.props.intl.formatMessage(translatableStrings.yLabel)
            }}
            key={this.props.userDataST.key ? `${this.props.userDataST.key}-0` : "default-chart-0"}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    ...state.app,
  }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    createNewChart: (data) => dispatch(actions.createNewChart(data)),
    initChartArchive: (data) => dispatch(actions.initChartArchive(data)),
    actions: bindActionCreators(actions as any, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Visualisation);
