import React from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions';
import './Thickness.scss';
import SelectList from '../../components/Selectlist/Selectlist';
import FormattedMessageCustom from '../../components/FormattedMessageCustom';
import {defineMessages} from 'react-intl';
import {ReactComponent as IconCheckmark} from '../../assets/icons/checkmark.svg';
// import { ReactComponent as IconArrowLeft } from '../../assets/icons/arrow-left.svg';
import {animateOutAndNavigate} from '../../utils/react';
import classNames from 'classnames';
import {Button, EButtonType} from '../../components/Button';
import {defined} from '../../utils/variableEvaluation';
import LanguageSwitcher from "../../components/LanguageSwitcher";
import BackBtn from '../../components/BackBtn'

const translatableStrings = defineMessages({
    title: {
        id: 'thickness.title',
        defaultMessage: 'Concrete Thickness (CNom)'
    },
    infoTitle: {
        id: 'thickness.infoTitle',
        defaultMessage: 'Information lorem ipsum'
    },
    info: {
        id: 'thickness.info',
        defaultMessage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'
    },
    next: {
        id: 'navigation.next',
        defaultMessage: 'Weiter'
    },
})

export const thicknesses = [35, 40, 45, 50, 55, 60];

interface State {
    thickness: number;
    hovered: boolean;
    calculateParameters: any;
    settingLabels: any;
}

interface Props {
    actions: any;
    history: any;
    userDataST: any;
    setUserDataThickness: any;
    setCalculateParameter: any;
    setSettingLabels: any;
    intl: any;
}

class Thickness extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.firstInput = React.createRef();
        this.state = {
            settingLabels: this.props.userDataST.SETTING_LABELS,
            thickness: this.props.userDataST.USERDATA_THICKNESS,
            calculateParameters: this.props.userDataST.CALCULATE_PARAMETER,
            hovered: false,
        }
    }

    private firstInput = null;

    componentDidMount() {
        document.body.classList.add('ThicknessPage');
        document.addEventListener('keyup', this.handlePresses);
    }

    componentWillUnmount() {
        document.body.classList.remove('ThicknessPage');
        document.removeEventListener('keyup', this.handlePresses);
    }

    handlePresses = (e) => {
        if (e.code === "ArrowRight") {
            this.setState({
                thickness: 35
            }, () => {
                this.firstInput.current.focus();
            })

            document.removeEventListener('keyup', this.handlePresses);
        } else if (e.code === "Tab") {
            document.removeEventListener('keyup', this.handlePresses);
            this.setState({
                thickness: 35
            });
        }
    }

    choose = (e) => {
        this.props.setUserDataThickness(this.state.thickness);

        let parameters = {...this.state.calculateParameters};

        parameters.x_mu = this.state.thickness;

        this.props.setCalculateParameter(parameters);

        let settingLabels = {...this.state.settingLabels};

        settingLabels.thinkness = this.state.thickness;

        this.props.setSettingLabels(settingLabels);

        animateOutAndNavigate(() => {
            this.props.history.push({
                pathname: `/results`
            });
        })
    }

    render() {
        const onMouseProps = {
            onMouseEnter: (e) => {
                this.setState({
                    hovered: true
                })
            },
            onMouseLeave: (e) => {
                this.setState({
                    hovered: false
                })
            }
        }

        return (
            <div className="thicknessContainer pageContainer">
                <SelectList
                    intl={this.props.intl}
                />
                <div className="main">
                    <div className="mainBg p-relative">
                        <BackBtn />
                        <FormattedMessageCustom id={translatableStrings.title.id}
                                                text={this.props.intl.formatMessage(translatableStrings.title)}>
                            <div className="header36 text-center stagger"></div>
                        </FormattedMessageCustom>
                        <form className="text-center" onSubmit={(e) => {
                            e.preventDefault();
                            this.choose(e);
                        }}>
                            <div
                                className={classNames("cards stagger", {
                                    "hasActiveChild": this.state.thickness || this.state.hovered
                                })}>
                                {thicknesses.map((val, idx) => {
                                    return <div className="thicknessCard"key={idx}><input
                                        ref={idx === 0 && this.firstInput} type="radio" id={`${val}thickness`}
                                        name={'thickness'} value={val}
                                        onChange={(e) => {
                                            this.setState({
                                                thickness: Number(e.target.value)
                                            })
                                        }}
                                        checked={this.state.thickness == val}
                                    />
                                        <label
                                            onClick={(e) => {
                                                this.setState({
                                                    thickness: val
                                                })
                                            }}
                                            {...onMouseProps}
                                            htmlFor={`${val}thickness`}
                                            className="card nonButton small"
                                        >
                                            <span className="checkmark">
                                                <IconCheckmark/>
                                            </span>
                                            {val} mm
                                        </label>
                                    </div>
                                })}

                            </div>

                            {<Button
                                className={classNames("my-5 staggerOut", {
                                    'invisible': !defined(this.state.thickness)
                                })}
                                submit
                                buttonType={EButtonType.PillMedium}
                                isDisabled={!defined(this.state.thickness)}
                                onClick={(e) => {
                                    this.choose(e)
                                }}
                            >
                                <FormattedMessageCustom id={translatableStrings.next.id}
                                                        text={this.props.intl.formatMessage(translatableStrings.next)}>
                                    <span className=""></span>
                                </FormattedMessageCustom>
                            </Button>}

                            <div className="stagger thicknessMessage">
                                <FormattedMessageCustom id={translatableStrings.infoTitle.id}
                                                        text={this.props.intl.formatMessage(translatableStrings.infoTitle)}>
                                    <p className="title21 font-500 text-left"></p>
                                </FormattedMessageCustom>
                                <FormattedMessageCustom id={translatableStrings.info.id}
                                                        text={this.props.intl.formatMessage(translatableStrings.info)}>
                                    <p className="font-16 font-300 text-left colorGrayDark"></p>
                                </FormattedMessageCustom>
                            </div>

                        </form>
                    </div>
                </div>
                <LanguageSwitcher isMobileMenu={true} intl={this.props.intl}/>
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        setUserDataThickness: (data) => dispatch(actions.setUserDataThickness(data)),
        setCalculateParameter: (data) => dispatch(actions.setCalculateParameter(data)),
        setSettingLabels: (data) => dispatch(actions.setSettingLabels(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Thickness);
