import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import './styles/index.scss';
import * as serviceWorker from './serviceWorker';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import prodConfig from './config/config.prod.json';
import stageConfig from './config/config.dev.json';
import { createStore, applyMiddleware, Store } from 'redux';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import createRootReducer, { RootState } from './reducers/index';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { IntlProvider } from 'react-intl-redux';
import { sideActions } from './middlewares';
import { createHashHistory } from 'history';
import de from './constants/locales/de.json';
import { flattenMessages } from './utils/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const historyS = createHashHistory();
const isProd = (window.location.host) === prodConfig.appUrl;
const isStage = (window.location.host) === stageConfig.appUrl;

let middleware;


if (isProd || isStage) {
  console.log("isProduction");
  middleware = applyMiddleware(
    routerMiddleware(historyS), // for dispatching history actions
    thunk,
    sideActions,
  );
} else {
  console.log("isNot Production or Stage");
  middleware = applyMiddleware(
    routerMiddleware(historyS), // for dispatching history actions
    thunk,
    sideActions,
    logger,
  );
}

const i18nInitialState = {
  locale: 'de',
  messages: {
    ...flattenMessages(de)
  },
}

export const store = createStore(
  (createRootReducer(historyS) as any),
  ({ intl: i18nInitialState } as any),
  (middleware as any)
) as Store<RootState>;

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey="6LejIscaAAAAAGdgu4OsDOr-yTgfNh6b66w4QUJz">
    <Provider store={store}>
      <ConnectedRouter history={historyS}>
        <IntlProvider locale="de" defaultLocale="de"
          onError={(err) => {
            if (err.code === "MISSING_TRANSLATION") {
              //console.warn("Missing translation", err.message);
              return;
            }
            throw err;
          }}>
          <ReduxToastr
            timeOut={0}
            newestOnTop={false}
            preventDuplicates
            position="top-center"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            closeOnToastrClick />
          <Routes />
        </IntlProvider>
      </ConnectedRouter>
    </Provider>
  </GoogleReCaptchaProvider>,
  document.getElementById('root') as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
