import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions';
import './ConcreteType.scss';
import SelectList from '../../components/Selectlist/Selectlist';
import FormattedMessageCustom from '../../components/FormattedMessageCustom';
import { defineMessages } from 'react-intl';
import { ReactComponent as IconArrowRight } from '../../assets/icons/arrow-right.svg';
import { animateOutAndNavigate, renderSwitch } from '../../utils/react';
import classNames from 'classnames';
import { Button, EButtonType } from '../../components/Button';
import { defined } from '../../utils/variableEvaluation';
import Select from 'react-select';
import { ReactComponent as IconArrowDown } from '../../assets/icons/arrow-down.svg';
import LanguageSwitcher from "../../components/LanguageSwitcher";
import BackBtn from '../../components/BackBtn'

const translatableStrings = defineMessages({
  title: {
    id: 'concreteType.title',
    defaultMessage: 'Concrete Type'
  },
  subtitle: {
    id: 'concreteType.subtitle',
    defaultMessage: 'Select your concrete type'
  },
  otherCement: {
    id: 'concreteType.otherCement',
    defaultMessage: 'Other cement type?'
  },
  contactUs: {
    id: 'concreteType.contactUs',
    defaultMessage: 'Contact Us'
  },
  otherCementDescription: {
    id: 'concreteType.otherCementDescription',
    defaultMessage: ' '
  },
  selectVariant: {
    id: 'concreteType.selectVariant',
    defaultMessage: 'Select variant'
  },
  wzValue: {
    id: 'concreteType.wzValue',
    defaultMessage: 'WZ value:'
  },
  next: {
    id: 'navigation.next',
    defaultMessage: 'Weiter'
  },
})


const wzValues = [0.35, 0.40, 0.45, 0.50, 0.55, 0.60]

interface State {
  concreteType: string;
  variant: {
    value: string;
    label: string;
  };
  hovered: boolean;
  wzValue: number;
  calculateParameters: any;
  settingLabels: any;
}

interface Props {
  actions: any;
  history: any;
  settings: any;
  userDataST: any;
  setUserDataconcreteType: any;
  setCalculateParameter: any;
  setSettingLabels: any;
  intl: any;
}

class ConcreteType extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.firstInput = React.createRef();
    this.state = {
      settingLabels: this.props.userDataST.SETTING_LABELS,
      concreteType: this.props.userDataST.USERDATA_CONCRETE_TYPE?.concreteType,
      variant: { ...this.props.userDataST.USERDATA_CONCRETE_TYPE?.variant },
      wzValue: this.props.userDataST.USERDATA_CONCRETE_TYPE?.wzValue,
      hovered: false,
      calculateParameters: this.props.userDataST.CALCULATE_PARAMETER
    }
  }

  private firstInput = null;

  componentDidMount() {
    document.body.classList.add('concreteTypePage');
    document.addEventListener('keyup', this.handlePresses);
  }

  componentWillUnmount() {
    document.body.classList.remove('concreteTypePage');
    document.removeEventListener('keyup', this.handlePresses);
  }

  handlePresses = (e) => {
    if (e.code === "ArrowRight") {
      this.setState({
        concreteType: 'kappe'
      }, () => {
        this.firstInput.current.focus();
      })

      document.removeEventListener('keyup', this.handlePresses);
    } else if (e.code === "Tab") {
      document.removeEventListener('keyup', this.handlePresses);
      this.setState({
        concreteType: 'kappe'
      });
    }
  }

  choose = (e) => {
    this.props.setUserDataconcreteType({
      'concreteType': this.state.concreteType, 'variant': this.state.variant, 'wzValue': this.state.wzValue
    });

    const values = this.props.settings.options['LOC'][this.props.userDataST.USERDATA_STANDORT]['options']['CCT'][this.state.concreteType]['options']['CCV'][this.state.variant.value]['options']['CCW'][this.state.wzValue].value.values;

    let parameters = { ...this.state.calculateParameters };

    values.map(value => {
      parameters[value.type.toLowerCase()] = value.value
    });

    this.props.setCalculateParameter(parameters);

    let settingLabels = { ...this.state.settingLabels };

    settingLabels.concrete_type = this.props.settings.options['LOC'][this.props.userDataST.USERDATA_STANDORT]['options']['CCT'][this.state.concreteType].value.label;
    settingLabels.concrete_type_variant = this.props.settings.options['LOC'][this.props.userDataST.USERDATA_STANDORT]['options']['CCT'][this.state.concreteType]['options']['CCV'][this.state.variant.value]['value'].label;
    settingLabels.concrete_type_wz_value = this.props.settings.options['LOC'][this.props.userDataST.USERDATA_STANDORT]['options']['CCT'][this.state.concreteType]['options']['CCV'][this.state.variant.value]['options']['CCW'][this.state.wzValue].value.label;

    this.props.setSettingLabels(settingLabels);

    animateOutAndNavigate(() => {
      this.props.history.push({
        pathname: `/thickness`
      });
    })
  }


  renderSubVariant = () => {
    let wzValueOptions = [];
    if (this.state.variant.value) {
      if (this.props.settings.options['LOC'][this.props.userDataST.USERDATA_STANDORT]['options']['CCT'][this.state.concreteType] && this.props.settings.options['LOC'][this.props.userDataST.USERDATA_STANDORT]['options']['CCT'][this.state.concreteType]['options']['CCV'][this.state.variant.value]) {

        const options = this.props.settings.options['LOC'][this.props.userDataST.USERDATA_STANDORT]['options']['CCT'][this.state.concreteType]['options']['CCV'][this.state.variant.value]['options']['CCW'];

        Object.keys(options).map(object => {
          wzValueOptions.push(options[object].value);
        });
      }
    }

    let subVariants = null;
    if (wzValueOptions.length > 0) {
      subVariants = (
        <div className="w-100 d-flex flex-wrap">
          <FormattedMessageCustom id={translatableStrings.wzValue.id}
            text={this.props.intl.formatMessage(translatableStrings.wzValue)}>
            <p className="title21 text-left font-500 my-4 w-100"></p>
          </FormattedMessageCustom>
          {wzValueOptions.map((wzValueOption, idx) => {
            return <div className="col-6 simpleInputs" key={idx}><input
              ref={this.firstInput} type="radio" id={`${wzValueOption.name}${idx}`}
              name={`${wzValueOption.name}${idx}`} value={wzValueOption.name}
              key={wzValueOption.name + "input"}
              onChange={(e) => {
                this.setState({
                  wzValue: Number(e.target.value)
                })
              }}
              checked={this.state.wzValue === wzValueOption.name}
            />
              <label
                onClick={(e) => {
                  this.setState({
                    wzValue: wzValueOption.name,
                  });
                }}
                htmlFor={`${wzValueOption.name}${idx}`}
                className="ml-3 font-16 font-300"
              >
                {wzValueOption.label}
              </label></div>
          })}
        </div>
      );
    }

    return subVariants || null;

  }

  renderVariant = () => {

    let options = [];

    if (this.props.settings.options['LOC'][this.props.userDataST.USERDATA_STANDORT]['options']['CCT'][this.state.concreteType]) {
      options = this.props.settings.options['LOC'][this.props.userDataST.USERDATA_STANDORT]['options']['CCT'][this.state.concreteType].value.children;
    }

    const variant = (
      <div
        className={classNames("col-12 col-sm-5 mx-auto card p-45 w-100 d-flex flex-row flex-wrap elasticHeight staggerOut", {})}>
        <FormattedMessageCustom id={translatableStrings.selectVariant.id}
          text={this.props.intl.formatMessage(translatableStrings.selectVariant)}>
          <div className="title21 w-100 text-left font-500 mb-4"></div>
        </FormattedMessageCustom>

        <Select
          value={this.state.variant}
          isSearchable={false}
          styles={{
            control: (base) => ({
              ...base,
              cursor: "pointer"
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              padding: 15,
              cursor: "pointer"
            }),
            menu: (provided, state) => ({
              ...provided,
              cursor: "pointer",
              color: state.selectProps.menuColor,
            }),
          }}
          components={{ 'DropdownIndicator': () => <IconArrowDown /> }}
          placeholder="Choose"
          className="dropdownInput"
          onChange={(e) => {
            this.setState({
              variant: e,
              wzValue: null
            });
          }}
          options={
            options.map((ccv, idx) => {
              return {
                'label': ccv.label,
                'value': ccv.name
              }
            })
          }
        />
        {this.state.variant && this.renderSubVariant()}
      </div>
    )

    return renderSwitch(this.state.concreteType, {
      'cem1': () => variant,
    }, () => variant)
  }

  render() {
    const onMouseProps = {
      onMouseEnter: (e) => {
        this.setState({
          hovered: true
        })
      },
      onMouseLeave: (e) => {
        this.setState({
          hovered: false
        })
      }
    }

    return (
      <div className="concreteTypeContainer pageContainer">
        <SelectList
          intl={this.props.intl}
        />
        <div className="main">
          <div className="mainBg p-relative">
            <BackBtn />
            <FormattedMessageCustom id={translatableStrings.title.id}
              text={this.props.intl.formatMessage(translatableStrings.title)}>
              <div className="header36 text-center stagger"></div>
            </FormattedMessageCustom>
            <FormattedMessageCustom id={translatableStrings.subtitle.id}
              text={this.props.intl.formatMessage(translatableStrings.subtitle)}>
              <div className="title21 my-3 stagger"></div>
            </FormattedMessageCustom>
            <form className="text-center d-flex w-100 align-items-center justify-content-between flexForm"
              onSubmit={(e) => {
                e.preventDefault();
                this.choose(e);
              }}>
              <div
                className={classNames("cards stagger col-12 px-0 px-sm-2 col-sm-4 w-100", {
                  "hasActiveChild": this.state.concreteType || this.state.hovered
                })}>
                {this.props.settings.obj[this.props.userDataST.USERDATA_STANDORT].CCT.map((cem, idx) => {
                  return <fieldset key={`fieldset-${idx}`}>
                    <input
                      ref={idx === 0 ? this.firstInput : this[cem.name]} type="radio"
                      id={cem.name} name="concreteType" value={cem.name}
                      onChange={(e) => {
                        this.setState({
                          concreteType: e.target.value,
                          variant: {
                            label: null,
                            value: null
                          },
                          wzValue: null
                        })
                      }}
                      checked={this.state.concreteType === cem.name}
                    />
                    <label
                      onClick={(e) => {
                        this.setState({
                          concreteType: cem.name,
                          variant: {
                            label: null,
                            value: null
                          },
                          wzValue: null
                        })
                      }}
                      {...onMouseProps}
                      htmlFor={cem.name}
                      className="card nonButton bridge small"

                    >
                      <p className="">{cem.label}</p>
                    </label>
                  </fieldset>
                })}
              </div>

              <IconArrowRight className={classNames('stagger staggerOut arrowRight ml-4', {
                'active': this.state.concreteType
              })} />

              {defined(this.state.concreteType) && this.renderVariant()}

            </form>
            {defined(this.state.concreteType) && this.state.variant && (this.state.wzValue || true) &&
              <Button
                className="mt-5 staggerOut"
                submit
                buttonType={EButtonType.PillMedium}
                isDisabled={!this.state.wzValue}
                onClick={(e) => {
                  this.choose(e)
                }}
              >
                <FormattedMessageCustom id={translatableStrings.next.id}
                  text={this.props.intl.formatMessage(translatableStrings.next)}>
                  <span className=""></span>
                </FormattedMessageCustom>
              </Button>}
              
            {!defined(this.state.concreteType) && <div className="subCards cards d-flex">
              <div className="card nonShadow col-12 col-sm-6 small otherCementType">
                <FormattedMessageCustom id={translatableStrings.otherCement.id}
                  text={this.props.intl.formatMessage(translatableStrings.otherCement)}>
                  <p className="title21 font-500"></p>
                </FormattedMessageCustom>
                <FormattedMessageCustom id={translatableStrings.otherCementDescription.id}
                  text={this.props.intl.formatMessage(translatableStrings.otherCementDescription)}>
                  <p className="font-16 font-300 text-left"></p>
                </FormattedMessageCustom>
                <Button
                  className=""
                  buttonType={EButtonType.BasicLink}
                  to="?modal=contact"
                >
                  <FormattedMessageCustom id={translatableStrings.contactUs.id}
                    text={this.props.intl.formatMessage(translatableStrings.contactUs)}>
                    <span className=""></span>
                  </FormattedMessageCustom>
                </Button>
              </div>
            </div>}
          </div>
        </div>
        <LanguageSwitcher isMobileMenu={true} intl={this.props.intl} />
      </div>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    ...state.app,
  }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    setUserDataconcreteType: (data) => dispatch(actions.setUserDataConcreteType(data)),
    setCalculateParameter: (data) => dispatch(actions.setCalculateParameter(data)),
    setSettingLabels: (data) => dispatch(actions.setSettingLabels(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConcreteType);
