import translationsMap from './locales/admin-locales-map'

export const REST_API_URL = 'https://admin.steeltec.what-sites.digital/api/v1/';
// export const REST_API_URL = 'https://steeltec-webapp-backend-stage.us.aldryn.io/api/v1/';

// import './config';
export const LANG_SET = 'LANG_SET';
export const LOADING = 'LOADING';
export const CLEAR_DATA = 'CLEAR_DATA';
export const UPDATE_LOCALES = 'UPDATE_LOCALES';
export const USERDATA_STANDORT = 'USERDATA_STANDORT';
export const USERDATA_BAUWERK = 'USERDATA_BAUWERK';
export const USERDATA_BAUWERK_DATA = 'USERDATA_BAUWERK_DATA';
export const USERDATA_BAUTEIL = 'USERDATA_BAUTEIL';
export const USERDATA_CONCRETE_TYPE = 'USERDATA_CONCRETE_TYPE';
export const CALCULATE_PARAMETER = 'CALCULATE_PARAMETER';
export const EXPOSITION_OPTIONS = 'EXPOSITION_OPTIONS';
export const SETTING_LABELS = 'SETTING_LABELS';
export const USERDATA_VARIANT = 'USERDATA_VARIANT';
export const USERDATA_THICKNESS = 'USERDATA_THICKNESS';
export const USERDATA_RESULT = 'USERDATA_RESULT';
export const CHANGE_USERDATA = 'CHANGE_USERDATA';
export const CREATE_NEW_CHART = 'CREATE_NEW_CHART';
export const INIT_CHART_ARCHIVE = 'INIT_CHART_ARCHIVE'
export const FETCH_ERROR = 'FETCH_ERROR';
export const FETCHED_SETTINGS = 'FETCHED_SETTINGS';
export const FETCHED_VARIABLES = 'FETCHED_VARIABLES';
export const FETCHED_RESULTS = 'FETCHED_RESULTS';
export const POSTED_MESSAGE = 'POSTED_MESSAGE';
export const FETCHED_TRANSLATIONS = 'FETCHED_TRANSLATIONS';

export const DEFAULT_LOCALE = 'de'

// Animations
export const animationConfig = () => {
  return {
    startSec: 0,
    easing: "linear",
    // easingStart: "Power4.easeInOut",// : "Expo.easeInOut",
    scale: 1,
    stagger: {
      each: 0.2,
      // ease: "Power4.easeInOut"
    }
  }
}

export const months = (lang) => {
  if (lang === "en") {
    return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  } else if (lang === "it") {
    return ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
  } else if (lang === "fr") {
    return ['février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'janvier']
  } else {
    return ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
  }
}


(window as any).getTranslations = function () {
  let translations = {};
  document.querySelectorAll("[data-translate]").forEach((el) => {
    let key = (el as any).dataset.translate;
    if (key.split('.')[1]) {
      translations[key.split('.')[0]] = translations[key.split('.')[0]] || {};
      translations[key.split('.')[0]][key.split('.')[1]] = (el as any).textContent.length ? (el as any).textContent : (el as any).getAttribute("placeholder");
    } else {
      translations[key] = (el as any).textContent.length ? (el as any).textContent : (el as any).getAttribute("placeholder");
    }
  })
  return translations;
};


// define server translations mapping
export const mergeTranslations = (locales, serverTranslations) => {
  const res = { ...locales };
  Object.keys(translationsMap).map((key) => {
    const targetKey = translationsMap[key]
    // if (serverTranslations[key]) console.log(key, targetKey);
    res[targetKey] = serverTranslations[key]
  });
  return res;
}