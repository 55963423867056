import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions';
import First from "./components/first";
import Second from "./components/second";
import Third from "./components/third";
import Logo from '../Logo';
import './Selectlist.scss';
import FormattedMessageCustom from '../FormattedMessageCustom';
import { Button, EButtonType } from '../Button';
import { thicknesses } from '../../containers/Thickness/Thickness';
import classNames from 'classnames';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as IconCH } from '../../assets/icons/CH.svg';
import { ReactComponent as IconDE } from '../../assets/icons/DE.svg';
import { ReactComponent as IconX } from '../../assets/icons/x.svg';
import { ReactComponent as IconUnknown } from '../../assets/icons/UNKNOWN.svg';
import LanguageSwitcher from "../LanguageSwitcher";
import isEqual from 'lodash/isEqual'
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { generateCSV } from '../../utils/csvExport';
import translatableStrings from './translations';
import SelectListExpert from './SelectList.Expert';

export const flags = {
  'de': <IconDE />,
  'ch': <IconCH />,
  'OTHER': <IconUnknown />,
}

interface State {
  thickness: number;
  exposition: any;
  variant: any;
  calculateParameters: any;
  settingLabels: any;
  expertModeParameters: any
}

interface Props {
  clearData: any;
  currentStep: number;
  intl: any;
  settings: any;
  results?: boolean;
  expert?: boolean;
  visible?: boolean;
  userDataST: any;
  onUpdateParams?: Function;
  createNewChart: any;
  changeLocale: any;
  history: any;
  actions: any;
  setCalculateParameter: any;
  setSettingLabels: any;
}

class SelectList extends React.Component<Props, State> {
  private isValueChanged = false;
  footerRef: any;
  footerHandlerEnabled: boolean;
  expositionOptions: Array<any>;

  constructor(props: any) {
    super(props);
    this.footerRef = React.createRef();
    this.footerHandlerEnabled = false;

    this.state = {
      settingLabels: this.props.userDataST.SETTING_LABELS,
      calculateParameters: this.props.userDataST.CALCULATE_PARAMETER,
      expertModeParameters: this.props.userDataST.EXPERT_MODE_CALCULATE_PARAMETER,
      exposition: this.props.userDataST.USERDATA_BAUTEIL.exposition,
      thickness: this.props.userDataST.USERDATA_THICKNESS,
      variant: this.props.userDataST.USERDATA_CONCRETE_TYPE?.variant
    }
  }

  async updateChart() {
    let parameters = { ...this.state.calculateParameters };
    let values = this.props.userDataST?.EXPOSITION_OPTIONS[this.state.exposition].values;

    values.map(value => {
      parameters[value.type.toLowerCase()] = value.value
    });

    parameters.x_mu = this.state.thickness;

    this.props.setCalculateParameter(parameters);
    let settingLabels = { ...this.state.settingLabels };

    settingLabels.exposition = this.props.userDataST?.EXPOSITION_OPTIONS[this.state.exposition].label;
    settingLabels.thinkness = this.state.thickness;
    this.props.setSettingLabels(settingLabels);
    await this.props.actions.fetchResults(parameters)

    this.props.createNewChart({
      expert: this.state.expertModeParameters,
      exposition: this.state.exposition,
      thickness: this.state.thickness
    });
  }

  resetWorkflow() {
    this.props.clearData();
    document.querySelector('#root').classList.remove('mobileSidebar');
    document.querySelector('#root').classList.remove('mobileHide');
    this.props.history.push({
      pathname: `/standort`
    });
  }

  resetSelection(updateChart = false) {
    this.setState({
      exposition: this.props.userDataST.USERDATA_BAUTEIL.exposition,
      thickness: this.props.userDataST.USERDATA_THICKNESS,
    }, () => {
      this.isValueChanged = false
      updateChart && this.updateChart();
    })
  }

  mapInspectabilityValue(v) {
    switch (v) {
      case 'Good': 
        return this.props.intl.formatMessage(translatableStrings.inspectabilityGood);
      case 'Bad':
        return this.props.intl.formatMessage(translatableStrings.inspectabilityBad)
    }
  }

  componentDidUpdate(prevProps): void {
    // update parameters from admin
    if (this.props.expert && !isEqual(
      this.props.userDataST.EXPERT_MODE_CALCULATE_PARAMETER,
      prevProps.userDataST.EXPERT_MODE_CALCULATE_PARAMETER
    )) {
      this.setState({
        expertModeParameters: { ...this.props.userDataST.EXPERT_MODE_CALCULATE_PARAMETER }
      }, () => {
        this.props.actions.fetchResults(this.state.expertModeParameters)
          .then(() => {
            this.props.createNewChart && this.props.createNewChart(null, true);
          })
      })
    }

    // TODO: move to separate component with sticky-footer markup
    if (!this.footerHandlerEnabled && window.document.querySelector(".sticky-footer") && this.props.expert) {
      const el = window.document.querySelector('.sticky-footer');
      this.footerHandlerEnabled = true
      let wrapper = document.querySelector('.simplebar-content-wrapper');
      wrapper.addEventListener('scroll', function (e) {
        const target = (e.target as any)
        const list = target.querySelector('.simplebar-content')
        const isOverlap = target.scrollTop >= (list.offsetHeight - target.offsetHeight - 78)
        if (isOverlap) {
          el.classList.add('sticky-footer_no-shadow')
        } else {
          el.classList.remove('sticky-footer_no-shadow')
        }
      })
    }

    // restore values in case of selecting previous calculations
    if ((!prevProps.userDataST.key && this.props.userDataST.key) || (prevProps.userDataST.key !== this.props.userDataST.key)) {
      if (this.props.expert) {
        this.setState({
          expertModeParameters: {
            ...this.props.userDataST.EXPERT_MODE_CALCULATE_PARAMETER
          }
        })
      } else {
        this.setState({
          settingLabels: { ...this.props.userDataST.SETTING_LABELS },
          exposition: this.props.userDataST.USERDATA_BAUTEIL.exposition,
          thickness: this.props.userDataST.USERDATA_THICKNESS,
          variant: this.props.userDataST.USERDATA_CONCRETE_TYPE?.variant
        })
      }
    }
  }

  getBuildingTranslation() {
    const userLabel = this.props.userDataST.USERDATA_BAUWERK_DATA?.name
    const options = this.props.settings?.options?.LOC[this.props.userDataST.USERDATA_STANDORT]?.options?.CON;
    
    try {
      return Object.keys(options).reduce((res: string, key: string) => {
        const opt = options[key]
        
        if (opt.value.name === userLabel || opt.value.label === userLabel) {
          const component = opt.options.COM[this.props.userDataST.USERDATA_BAUTEIL.bauteil].value
          this.expositionOptions = component.children[0].children
          return `${opt.value.label} | ${component?.label}`;
        }
        return res;
      }, '')
    } catch(err) {
      return `${this.props.userDataST?.SETTING_LABELS.building} | ${this.props.userDataST?.SETTING_LABELS.component}`
    }
  }

  renderResultsBar = () => {
    return <div className={`selectList ${this.props.expert ? 'selectList_expert' : ''}`}>

      <FormattedMessageCustom id={translatableStrings.resultsTitle.id}
        text={this.props.intl.formatMessage(translatableStrings.resultsTitle)}>
        <div className="title21 font-500 mb-4"></div>
      </FormattedMessageCustom>
      <hr />

      {!this.props.expert && <>
        <div className="bauwerkParams p-relative">
          <div className="flag">
            {flags[this.props.userDataST?.USERDATA_STANDORT]}
          </div>
          <FormattedMessageCustom id={translatableStrings.title.id}
            text={this.props.intl.formatMessage(translatableStrings.title)}>
            <div className="font-16 d-flex font-500 selectTitle colorMain"></div>
          </FormattedMessageCustom>
          {this.getBuildingTranslation()}
          <br />
          <FormattedMessageCustom id={translatableStrings.exposition.id}
            text={this.props.intl.formatMessage(translatableStrings.exposition)}>
            <div className="d-inline"></div>
          </FormattedMessageCustom>: {this.props.userDataST?.SETTING_LABELS.exposition_class}
          <br />
          <FormattedMessageCustom id={translatableStrings.inspectability.id}
            text={this.props.intl.formatMessage(translatableStrings.inspectability)}>
            <div className="d-inline"></div>
          </FormattedMessageCustom>: {this.mapInspectabilityValue(this.props.userDataST?.SETTING_LABELS.inspectability)}
        </div>

        {this.props.userDataST.USERDATA_STANDORT === 'de' &&
          <div className="p-relative">
            <div className="icon" onClick={(e) => {
              this.props.history.push({
                pathname: `/concreteType`
              });
            }}>
              <EditIcon />
            </div>
            <FormattedMessageCustom id={translatableStrings.concreteType.id}
              text={this.props.intl.formatMessage(translatableStrings.concreteType)}>
              <div className="font-16 font-500 selectTitle"></div>
            </FormattedMessageCustom>

            <div className="d-inline">
              {this.props.userDataST?.SETTING_LABELS.concrete_type}
            </div>
            <br />
            <div className="d-inline bauwerkParams">
              {this.props.userDataST?.SETTING_LABELS.concrete_type_variant} ({this.props.userDataST?.SETTING_LABELS.concrete_type_wz_value})
            </div>
          </div>
        }
        <FormattedMessageCustom
          id={translatableStrings.concreteThickness.id}
          text={this.props.intl.formatMessage(translatableStrings.concreteThickness)}>
          <div className="font-16 font-500 selectTitle"></div>
        </FormattedMessageCustom>

        <div className="d-flex flex-wrap flex-row justify-content-between">
          {thicknesses.map((val, idx) => {
            return (<div key={idx}
              onClick={(e) => {
                this.isValueChanged = true;
                this.setState({
                  thickness: val
                }, async () => {
                  await this.updateChart();
                  this.props.onUpdateParams({ 
                    ...this.props.userDataST, 
                    USERDATA_THICKNESS: this.state.thickness,
                    SETTING_LABELS: this.props.userDataST.SETTING_LABELS
                  });
                })
              }}
              className={classNames("pill thickness font-12", {
                'selected': this.state.thickness == val
              })}>
              {val}</div>)
          })}
        </div>

        <FormattedMessageCustom
          id={translatableStrings.exposition.id}
          text={this.props.intl.formatMessage(translatableStrings.exposition)}>
          <div className="font-16 font-500 selectTitle mt-4"></div>
        </FormattedMessageCustom>

        <div className="d-flex flex-wrap flex-row justify-content-between">
          {Object.keys(this.props.userDataST?.EXPOSITION_OPTIONS || {}).map((val, idx) => {
            const label = this.expositionOptions.find(opt => opt.name === val)
            return (<div
              key={idx}
              onClick={(e) => {
                this.isValueChanged = true;
                this.setState({
                  exposition: val
                }, async () => {
                  await this.updateChart();
                  this.props.onUpdateParams({ 
                    ...this.props.userDataST,
                    USERDATA_BAUTEIL: {
                      ...this.props.userDataST.USERDATA_BAUTEIL,
                      exposition: this.state.exposition,
                    },
                    SETTING_LABELS: this.props.userDataST.SETTING_LABELS
                  });
                })
              }}
              className={classNames("pill thickness font-12", {
                'selected': this.state.exposition == val
              })}>{label.label}</div>)
          })}
        </div>

        <FormattedMessageCustom id={translatableStrings.reset.id}
          text={this.props.intl.formatMessage(translatableStrings.reset)}>
          <button className="my-3 basicButton nonButton font-14 resetBtn"
            disabled={this.isValueChanged ? false : true}
            onClick={() => this.resetSelection(true)}
          />
        </FormattedMessageCustom>

        <Button
          className="mt-0 w-100"
          buttonType={EButtonType.InvertedPill}
          onClick={() => this.resetWorkflow()}
        >
          <FormattedMessageCustom id={translatableStrings.createNew.id}
            text={this.props.intl.formatMessage(translatableStrings.createNew)}>
            <span className=""></span>
          </FormattedMessageCustom>
        </Button>
      </>}

      {this.props.expert && <>
        <SelectListExpert intl={this.props.intl} 
          expertModeParameters={this.state.expertModeParameters} 
          onChangeValue={(key, value) => {
            this.setState({
              expertModeParameters: { ...this.state.expertModeParameters, [key]: value }
            }, () => {
              this.props.onUpdateParams({ 
                ...this.props.userDataST, 
                EXPERT_MODE_CALCULATE_PARAMETER: this.state.expertModeParameters 
              });
            })
          }}
        />

        <div className="sticky-footer" ref={this.footerRef}>
          <FormattedMessageCustom id={translatableStrings.reset.id}
            text={this.props.intl.formatMessage(translatableStrings.reset)}>
            <button className="my-3 basicButton nonButton font-14"
              onClick={(e) => {
                this.setState({
                  expertModeParameters: this.props.userDataST.EXPERT_MODE_CALCULATE_PARAMETER
                });
                this.props.actions.fetchResults(this.props.userDataST.EXPERT_MODE_CALCULATE_PARAMETER);
              }}
            />
          </FormattedMessageCustom>

          <Button
            className="mt-0 w-100"
            buttonType={EButtonType.InvertedPill}
            onClick={(e) => {
              this.props.actions.fetchResults(this.state.expertModeParameters)
                .then(() => {
                  this.props.createNewChart({ expert: this.state.expertModeParameters });
                })
            }}
          >
            <FormattedMessageCustom id={translatableStrings.createNew.id}
              text={this.props.intl.formatMessage(translatableStrings.createNew)}>
              <span className=""></span>
            </FormattedMessageCustom>
          </Button>
          <div className="d-flex justify-content-center">
            <FormattedMessageCustom id={translatableStrings.exportFile.id}
              text={this.props.intl.formatMessage(translatableStrings.exportFile)}>
              <button className="my-3 basicButton nonButton font-14"
                onClick={() => {
                  generateCSV(this.state.expertModeParameters, this.props.userDataST.results)
                }}
              />
            </FormattedMessageCustom>
          </div>
        </div>
      </>}
    </div>
  }

  render() {
    return (
      <>
        <div className="mobileHeader">
          <Logo />
          <FormattedMessageCustom id={translatableStrings.lebenszeitberechnung.id}
            text={this.props.intl.formatMessage(translatableStrings.lebenszeitberechnung)}>
            <p className="header21 my-0 ml-auto"></p>
          </FormattedMessageCustom>
        </div>
        <div className={classNames("sidebar", {
          'visible': this.props.visible,
          'nonResultsPage': !this.props.results,
          'sidebar_expert': this.props.expert
        })}>
          <SimpleBar style={{ maxHeight: '100%' }}>
            <div className="d-none d-sm-block">
              <Logo />
            </div>
            <div className="d-block d-sm-none settingsTitle">
              <FormattedMessageCustom id={translatableStrings.settings.id}
                text={this.props.intl.formatMessage(translatableStrings.settings)}>
                <div className="font-24 my-0 ml-auto py-4"></div>
              </FormattedMessageCustom>
            </div>
            <Button className="d-flex d-sm-none closeSettings" onClick={() => {
              document.querySelector('#root').classList.add('mobileHide');
              setTimeout(() => {
                document.querySelector('#root').classList.remove('mobileSidebar');
                document.querySelector('#root').classList.remove('mobileHide');
              }, 300);
            }}>
              <IconX />
            </Button>
            <hr className="mt-0 d-block d-sm-none" />

            {this.props.results ? this.renderResultsBar() : <div className={classNames('selectList', {})}>
              <FormattedMessageCustom id={translatableStrings.lebenszeitberechnung.id}
                text={this.props.intl.formatMessage(translatableStrings.lebenszeitberechnung)}>
                <p className="header21"></p>
              </FormattedMessageCustom>
              <First
                completed={this.props.currentStep > 1}
                active={this.props.currentStep == 1}
                intl={this.props.intl}
                userDataST={this.props.userDataST}
              />
              <Second
                completed={this.props.currentStep > 2}
                active={this.props.currentStep == 2}
                intl={this.props.intl}
                userDataST={this.props.userDataST}
              />
              <Third
                completed={this.props.currentStep > 3}
                active={this.props.currentStep == 3}
                intl={this.props.intl}
                userDataST={this.props.userDataST}
              />
            </div>}
            {this.props.expert && <LanguageSwitcher isExpertMode={this.props.expert} isMobileMenu={false} intl={this.props.intl} />}
          </SimpleBar>
          {!this.props.expert && <LanguageSwitcher isMobileMenu={false} intl={this.props.intl} />}
        </div>
      </>
    )
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    ...state.app,
  }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    changeLocale: (data) => dispatch(actions.changeLocale(data)),
    clearData: (data) => dispatch(actions.clearDataAction(data)),
    setCalculateParameter: (data) => dispatch(actions.setCalculateParameter(data)),
    setSettingLabels: (data) => dispatch(actions.setSettingLabels(data)),
    actions: bindActionCreators(actions as any, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectList);
