// import style from './Logo.module.scss'; // Import css modules stylesheet as styles
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/imgs/logo-new.png';

export interface Props {
}

const Logo = (props: Props) => {
  return <Link to="/">
    <img alt="logo" src={logo} className='logo' />
  </Link>
};

export default Logo;
