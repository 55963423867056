import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ReactTooltip from 'react-tooltip';
import { RootState } from '../../reducers';
import * as actions from '../../actions';
import './Bauteil.scss';
import SelectList from '../../components/Selectlist/Selectlist';
import FormattedMessageCustom from '../../components/FormattedMessageCustom';
import { defineMessages } from 'react-intl';
import { ReactComponent as IconCheckmark } from '../../assets/icons/checkmark.svg';
import { ReactComponent as Questionmark } from '../../assets/icons/question.svg';
import { animateOutAndNavigate, renderSwitch } from '../../utils/react';
import classNames from 'classnames';
import { Button, EButtonType } from '../../components/Button';
import { defined } from '../../utils/variableEvaluation';
import LanguageSwitcher from "../../components/LanguageSwitcher";
import BackBtn from '../../components/BackBtn'

//TO-DO: on page load default XD class button is not selected
//TO-DO: translation fix

const translatableStrings = defineMessages({
  title: {
    id: 'bauteil.title',
    defaultMessage: 'Bauteil'
  },

  subtitle: {
    id: 'bauteil.subtitle',
    defaultMessage: 'Welches Bauteil möchten sie untersuchen?'
  },
  inspectability: {
    id: 'bauteil.inspectability',
    defaultMessage: 'Inspectability'
  },
  exposition: {
    id: 'bauteil.exposition',
    defaultMessage: 'Exposition'
  },
  expositionDescription: {
    id: 'bauteil.expositionDescription',
    defaultMessage: 'Die Einordnung der Chloridexpositionen oben ist lediglich als Orientierung zu verstehen und ohne Gewähr. Im Idealfall wird die konkrete Chloridexposition am Bauteil z.B. anhand von Bohrmehlanalysen bestimmt.)'
  },
  inspectabilityDescription: {
    id: 'bauteil.inspectabilityDescription',
    defaultMessage: ''
  },
  high: {
    id: 'bauteil.high',
    defaultMessage: 'High'
  },
  medium: {
    id: 'bauteil.medium',
    defaultMessage: 'Medium'
  },
  low: {
    id: 'bauteil.low',
    defaultMessage: 'Low'
  },
  good: {
    id: 'bauteil.good',
    defaultMessage: 'Good'
  },
  bad: {
    id: 'bauteil.bad',
    defaultMessage: 'Bad'
  },
  inspectabilityFixed: {
    id: 'bauteil.inspectabilityFixed',
    defaultMessage: 'Fixed value of ß = 1.5'
  },
  highDescription: {
    id: 'bauteil.highDescription',
    defaultMessage: 'Autobahn Süddeutschland und Mittelgebirge'
  },
  mediumDescription: {
    id: 'bauteil.mediumDescription',
    defaultMessage: 'Autobahn Deutschland ohne Süd, Landstrasse Süddeutschland'
  },
  lowDescription: {
    id: 'bauteil.lowDescription',
    defaultMessage: 'Landstrassen Deutschland ohne Süd'
  },
  goodDescription: {
    id: 'bauteil.goodDescription',
    defaultMessage: 'GLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.ood'
  },
  badDescription: {
    id: 'bauteil.badDescription',
    defaultMessage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.'
  },
  next: {
    id: 'navigation.next',
    defaultMessage: 'Weiter'
  },
})

interface State {
  bauteil: string;
  exposition: string;
  inspectability: string;
  hovered: boolean;
  calculateParameters: any,
  settingLabels: any,
  activeXdClass: string
}

interface Props {
  actions: any;
  history: any;
  userDataST: any;
  setUserDataBauteil: any;
  setCalculateParameter: any;
  setSettingLabels: any;
  setExpositionOptions: any;
  intl: any;
  settings: any;
}

class Bauteil extends React.Component<Props, State> {
  subOptions: {};
  componentOptions: any[];
  customSubOptions: {};
  expositionData: {
    exposition_class: ''
  };
  expositionOptions: {};
  expositionClasses: {};
  private firstInput = null;

  constructor(props: any) {  
    super(props);
    this.firstInput = React.createRef();
    
    const state = {
      settingLabels: this.props.userDataST.SETTING_LABELS,
      bauwerk: this.props.userDataST.USERDATA_BAUWERK,
      bauteil: this.props.userDataST.USERDATA_BAUTEIL?.bauteil,
      inspectability: this.props.userDataST.USERDATA_BAUTEIL?.inspectability,
      exposition: this.props.userDataST.USERDATA_BAUTEIL?.exposition,
      hovered: false,
      calculateParameters: this.props.userDataST.CALCULATE_PARAMETER,
      activeXdClass: ''
    }
    this.setComponentOptions()
    // remove the cached bauteil value, in case it's not present in component options
    if (this.componentOptions) {
      let index = this.componentOptions.findIndex(o => o.name === state.bauteil)
      if (index === -1) state.bauteil = null
    }
    if (state.bauteil) this.setExpositionData(state.bauteil)
    this.state = state;
  }

  componentDidMount() {
    document.body.classList.add('BauteilPage');
    document.addEventListener('keyup', this.handlePresses);
  }

  componentWillUnmount() {
    document.body.classList.remove('BauteilPage');
    document.removeEventListener('keyup', this.handlePresses);
  }

  handlePresses = (e) => {
    if (e.code === "ArrowRight") {
      this.setState({
        bauteil: 'kappe'
      }, () => {
        this.firstInput.current.focus();
      })
      document.removeEventListener('keyup', this.handlePresses);
    } else if (e.code === "Tab") {
      document.removeEventListener('keyup', this.handlePresses);
      this.setState({ bauteil: 'kappe' });
    }
  }

  choose = (e) => {
    this.props.setUserDataBauteil({
      'bauteil': this.state.bauteil,
      'inspectability': this.state.inspectability,
      'exposition': this.state.exposition,
    });

    let options = this.customSubOptions[this.state.bauteil];
    const activeXdClass = this.state.activeXdClass || Object.keys(options)[0];
    let values = options[activeXdClass]['options']['EXL'][this.state.exposition].values;
    let parameters = { ...this.state.calculateParameters };

    values.map(value => {
      parameters[value.type.toLowerCase()] = value.value
    });

    let cutoffPoint = 0.31;
    if (this.state.inspectability === 'bad') {
      cutoffPoint = 0.07;
    }
    
    parameters.inspectability = cutoffPoint;
    this.props.setCalculateParameter(parameters);
    let settingLabels = { ...this.state.settingLabels };

    settingLabels.component = this.props.settings.options['LOC'][this.props.userDataST.USERDATA_STANDORT]['options']['CON'][this.props.userDataST.USERDATA_BAUWERK]['options']['COM'][this.state.bauteil].value.label;
    settingLabels.exposition = options[activeXdClass]['options']['EXL'][this.state.exposition].label;

    if (this.expositionData) {
      settingLabels.exposition_class = this.expositionData.exposition_class;
    }
    settingLabels.inspectability = this.state.inspectability.charAt(0).toUpperCase() + this.state.inspectability.slice(1);
    
    this.props.setSettingLabels(settingLabels);
    this.props.setExpositionOptions(options[activeXdClass]['options']['EXL']);

    animateOutAndNavigate(() => {
      this.props.history.push({
        pathname: this.props.userDataST.USERDATA_STANDORT === 'de' ? `/concreteType` : `/thickness`
      });
    })
  }

  setComponentOptions() {
    const options = this.props.settings.options['LOC'][this.props.userDataST.USERDATA_STANDORT]['options']['CON'][this.props.userDataST.USERDATA_BAUWERK]['options']['COM'];
    let customOptions = [];
    let customSubOptions = {};

    if (options) {
      Object.entries(options).map((option, idx) => {
        // @ts-ignore
        customOptions.push(option[1].value);

        // @ts-ignore
        customSubOptions[option[1].value.name] = option[1].options['EXC'];
      });
      this.componentOptions = customOptions;
    }
    this.customSubOptions = customSubOptions;
  }

  renderSubInputs = (name) => {
    let inputs = [];
    const isExposition = !!(name === 'exposition')

    if (isExposition) {
      Object.entries(this.expositionOptions).map(option => {
        let optionData = JSON.parse(JSON.stringify(option[1]));
        // @ts-ignore
        inputs.push({
          name: 'exposition',
          value: optionData.name,
          label: <p className="">{optionData.label}</p>,
          description: <p className="">{optionData.help_text}</p>
        });
      });

    } else {
      if (this.state.bauteil === 'other') {
        inputs = [
          <span className="text-left font-300">
            <FormattedMessageCustom id={translatableStrings.inspectabilityFixed.id}
              text={this.props.intl.formatMessage(translatableStrings.inspectabilityFixed)}>
              <p className=""></p>
            </FormattedMessageCustom>
          </span>
        ];

      } else {
        inputs = [
          {
            name: 'inspectability',
            value: 'good',
            label: <FormattedMessageCustom id={translatableStrings.good.id}
              text={this.props.intl.formatMessage(translatableStrings.good)}>
              <p className=""></p>
            </FormattedMessageCustom>,
            description: <FormattedMessageCustom id={translatableStrings.goodDescription.id}
              text={this.props.intl.formatMessage(translatableStrings.goodDescription)}>
              <p className=""></p>
            </FormattedMessageCustom>
          },
          {
            name: 'inspectability',
            value: 'bad',
            label: <FormattedMessageCustom id={translatableStrings.bad.id}
              text={this.props.intl.formatMessage(translatableStrings.bad)}>
              <p className=""></p>
            </FormattedMessageCustom>,
            description: <FormattedMessageCustom id={translatableStrings.badDescription.id}
              text={this.props.intl.formatMessage(translatableStrings.badDescription)}>
              <p className=""></p>
            </FormattedMessageCustom>
          },
        ]
      }
    }

    return <>
      {inputs.map((inputObj, idx) => {
        return inputObj.name ? (
          <div className="col-12 d-flex flex-row simpleInputs" key={`simple-input-${idx}`}>
            <input
              type="radio" id={inputObj.value} name={inputObj.name} value={inputObj.value}
              onChange={(e) => {
                this.setState({
                  [inputObj.name]: e.target.value
                } as any)
              }}
              checked={this.state[inputObj.name] === inputObj.value}
            />
            <label
              onClick={(e) => {
                this.setState({
                  [inputObj.name]: inputObj.value
                } as any)
              }}
              htmlFor={inputObj.value}
              className="text-left pointer"
            >
              {inputObj.label}
              <span>
                {inputObj.description}
              </span>
            </label>
          </div>) : (
          inputObj
        )
      })}
    </>
  }

  renderSubView = () => {
    const hasExposition = this.customSubOptions[this.state.bauteil];
    const subView = (
      <div className="d-flex flex-row flex-wrap staggerOut subView">
        {hasExposition && <div className="col-12 col-sm-6 px-0 subViewColumn">
          <div className="subViewTitle">
            <FormattedMessageCustom id={translatableStrings.exposition.id}
              text={this.props.intl.formatMessage(translatableStrings.exposition)}>
              <div className="title21 d-flex font-500" />
            </FormattedMessageCustom>

            <span className="question-icon" data-for="exposition-tooltip" data-tip><Questionmark /></span>

            <ReactTooltip id="exposition-tooltip" className="question-tooltip" backgroundColor="#fff" textColor="#444">
              <FormattedMessageCustom id={translatableStrings.expositionDescription.id}
                text={this.props.intl.formatMessage(translatableStrings.expositionDescription)}>
                <span></span>
              </FormattedMessageCustom>
            </ReactTooltip>
      
          </div>
          <div className="d-flex subViewSelector">
            {this.expositionClasses && Object.keys(this.expositionClasses).map((exClassKey: any, i: number) => {
              const exClass = this.expositionClasses[exClassKey]
              return <div className={`xdSelector ${this.state.activeXdClass === exClassKey ? 'xdSelectorActive' : ''}`}
                key={i}
                onClick={() => this.setExpositionData(this.state.bauteil, exClassKey)}>
                {exClass.value.name}
              </div>
            })}
          </div>
          {this.renderSubInputs('exposition')}
        </div>}
        <div className="col-12 col-sm-6 px-0 px-sm-2 subViewColumn">
          <div className="subViewTitle">
            <FormattedMessageCustom id={translatableStrings.inspectability.id}
              text={this.props.intl.formatMessage(translatableStrings.inspectability)}>
              <div className="title21 d-flex font-500 "></div>
            </FormattedMessageCustom>

            <span className="question-icon" data-for="inspectability-tooltip" data-tip><Questionmark /></span>
            <ReactTooltip id="inspectability-tooltip" className="question-tooltip" backgroundColor="#fff" textColor="#444">
              <FormattedMessageCustom id={translatableStrings.inspectabilityDescription.id}
                text={this.props.intl.formatMessage(translatableStrings.inspectabilityDescription)}>
                <span></span>
              </FormattedMessageCustom>
            </ReactTooltip>
          </div>
          {this.renderSubInputs('inspectability')}
        </div>
      </div>
    )
    return renderSwitch(this.state.bauteil, {
      'kappe': () => subView,
    }, () => subView)
  }

  setExpositionData = (bauteil: string, xdClass?: string) => {
    const options = this.customSubOptions[bauteil]
    
    if (options) {
      this.expositionClasses = options;
      if (!xdClass) {
        const xd = Object.keys(options)[0]
        this.expositionData = options[xd].value;
        this.expositionOptions = options[xd].options['EXL'];
        this.setState({ activeXdClass: xd })
      } else {
        this.expositionData = options[xdClass].value;
        this.expositionOptions = options[xdClass].options['EXL'];
        this.setState({ activeXdClass: xdClass })
      }

    } else {
      this.expositionData = { exposition_class: null };
      this.expositionOptions = {};
    }
  }

  render() {
    const onMouseProps = {
      onMouseEnter: (e) => {
        this.setState({
          hovered: true
        })
      },
      onMouseLeave: (e) => {
        this.setState({
          hovered: false
        })
      }
    }

    let pageTitle = translatableStrings.title;

    return (
      <div className="bauteilContainer pageContainer">
        <ReactTooltip />
        <SelectList
          intl={this.props.intl}
        />
        <div className="main">
          <div className="mainBg p-relative">
            <BackBtn pathname='/bauwerk' />
            <FormattedMessageCustom id={pageTitle.id} text={`${this.props.intl.formatMessage(pageTitle)} (${this.props.userDataST.USERDATA_BAUWERK_DATA.label})`}>
              <div className="header36 text-center stagger"></div>
            </FormattedMessageCustom>
            <FormattedMessageCustom id={translatableStrings.subtitle.id}
              text={this.props.intl.formatMessage(translatableStrings.subtitle)}>
              <div className="title21 my-3 stagger"></div>
            </FormattedMessageCustom>
            <form className="text-center flexForm" onSubmit={(e) => {
              e.preventDefault();
              this.choose(e);
            }}>
              <div
                className={classNames("cards components stagger", {
                  "hasActiveChild": this.state.bauteil || this.state.hovered
                })}>

                {this.componentOptions.map((contructionType, idx) => {
                  return (<div className={`card-parent card-parent-${idx}`} key={idx}>
                    <input
                      ref={this.firstInput} type="radio"
                      id={contructionType.name}
                      name="bauteil"
                      value={contructionType.name}
                      onChange={(e) => {
                        this.setExpositionData(e.target.value)
                        this.setState({
                          bauteil: e.target.value,
                          exposition: null,
                          inspectability: null
                        })
                      }}
                      checked={this.state.bauteil === contructionType.name}
                    />
                    <label
                      onClick={(e) => {
                        this.setExpositionData(contructionType.name)
                        this.setState({
                          bauteil: contructionType.name,
                          exposition: null,
                          inspectability: null
                        })
                      }}
                      {...onMouseProps}
                      htmlFor={contructionType.name}
                      className="card nonButton bridge small"
                    >
                      <span className="checkmark">
                        <IconCheckmark />
                      </span>
                      <p className="">{contructionType.label}</p>
                    </label>
                  </div>)
                })}
              </div>

              {this.state.bauteil && this.renderSubView()}

              {defined(this.state.bauteil) && this.state.exposition && (this.state.inspectability || this.state.bauteil === 'other') &&
                <Button
                  className="mt-5 staggerOut"
                  submit
                  buttonType={EButtonType.PillMedium}
                  isDisabled={!defined(this.state.bauteil)}
                  onClick={(e) => {
                    this.choose(e)
                  }}
                >
                  <FormattedMessageCustom id={translatableStrings.next.id}
                    text={this.props.intl.formatMessage(translatableStrings.next)}>
                    <span className=""></span>
                  </FormattedMessageCustom>
                </Button>}
            </form>
            <LanguageSwitcher isMobileMenu={true} intl={this.props.intl} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    ...state.app,
  }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    setUserDataBauteil: (data) => dispatch(actions.setUserDataBauteil(data)),
    setCalculateParameter: (data) => dispatch(actions.setCalculateParameter(data)),
    setSettingLabels: (data) => dispatch(actions.setSettingLabels(data)),
    setExpositionOptions: (data) => dispatch(actions.setExpositionOptions(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bauteil);
